import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { authorize } from '../api/API';
import * as APIConstants from '../api/APIConstants';
import MainLayout from '../layouts/MainLayout';
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Divider,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Radio,
    RadioGroup,
    Select,
    SkeletonText,
    Spacer,
    Stack,
    Text,
    Textarea,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { faker } from '@faker-js/faker';
import ReactInputMask from 'react-input-mask';
import dayjs from 'dayjs';
import AddressForm from '../components/AddressForm';
import debounce from 'lodash.debounce';
import PanelHeading from '../components/PanelHeading';

/**
 * This is an example of a generic "Resource Display" page.
 * It uses the Medplum `<ResourceTable>` component to display a resource.
 * @returns A React component that displays a resource.
 */
export function CreateVisit(): JSX.Element {
    const { getAccessTokenSilently } = useAuth0();
    // const [claims, setClaims] = useState<any[] | undefined>(undefined);

    const token = getAccessTokenSilently();
    const navigate = useNavigate();
    const toast = useToast();
    const { clientID } = useParams();
    const [client, setClient] = useState<any | undefined>(undefined);

    useEffect(() => {
        // please fix this!
        if (token == null) {
            return;
        }

        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/clients/${clientID}`)
                .then((res): void => {
                    const client = res.data;
                    console.log('client ', client);
                    setClient(res.data);
                });
        })();
    }, []);

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        setValue,
        control,
        watch,
    } = useForm();

    const watchTelehealth = watch('telehealth', false);
    const watchPlaceOfService = watch('placeOfService', false);
    const watchVisitType = watch('visitType', false);

    const { fields, append } = useFieldArray({
        control,
        name: 'serviceLines',
    });

    const appendServiceLine = () => {
        append({ procedureCode: '', quantity: 1 });
    };

    const logVisit = async (token: string, visit: any) => {
        return authorize(token).post(`/visits`, visit);
    };

    useEffect(() => {
        console.log(errors);
    }, [errors]);

    async function onSubmit(values: any) {
        console.log('Submitting form', values);

        await logVisit(await token, values)
            .then(() => {
                console.log('Visit created');
                navigate('/visits?created=true');
            })
            .catch((err) => {
                console.error('Error creating claim', err);
                if (err.response.status === 400) {
                    toast({
                        title: 'Error creating claim',
                        description: 'Claim already exists',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                    return;
                }

                toast({
                    title: 'Error creating claim. Please contact support@joinloula.com for assistance.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    async function demoData() {
        setValue(
            'dateOfService',
            dayjs(faker.date.past()).format('MM/DD/YYYY'),
        );

        setValue('durationMinutes', faker.number.int({ min: 1, max: 90 }));

        setValue('notes', faker.lorem.paragraphs(5));

        setValue('facility.name', faker.company.name());
        setValue('facility.npi', faker.number.int());
    }

    /**
     * Enum "visit_types" {
  "initial" // CA Z1032
  "prenatal" // CA Z1034
  "postpartum" // CA Z1038
  "vaginal_delivery" // CPT 59409
  "vaginal_delivery_after_csection" // CPT 59612
  "csection" // CPT 59620
  "miscarriage" // HCPCS T1033
  "abortion" // CPT 59840
}
     */

    const durationRequiredHelper = (visitType: string) => {
        const visitTypeObj = APIConstants.VISIT_TYPES.find((type) =>
            type.options.find((option) => option.value === visitType),
        );
        if (visitTypeObj) {
            const option = visitTypeObj.options.find(
                (option) => option.value === visitType,
            );
            return option?.durationRequired;
        }
        return false;
    };

    const HospitalForm = () => (
        <>
            <FormControl isInvalid={!!errors.facilityName} mt={4}>
                <FormLabel htmlFor="facility.name">Hospital Name</FormLabel>
                <Input
                    placeholder=""
                    {...register('facility.name', {
                        required: 'This is required',
                        minLength: {
                            value: 4,
                            message: 'Minimum length should be 4',
                        },
                    })}
                />
                <FormErrorMessage>
                    {errors.facilityName &&
                        errors.facilityName.message?.toString()}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.facilityNpi} mt={4}>
                <FormLabel htmlFor="facilityNpi">Hospital NPI</FormLabel>
                <Input
                    id="facilityNpi"
                    placeholder="NPI"
                    {...register('facility.npi', {
                        required: 'This is required',
                        minLength: {
                            value: 10,
                            message: 'Minimum length should be 10',
                        },
                    })}
                />
                <FormHelperText>
                    (If you have this info it would be super helpful, but it's
                    ok if you don't)
                </FormHelperText>
                <FormErrorMessage>
                    {errors.facilityNpi &&
                        errors.facilityNpi.message?.toString()}
                </FormErrorMessage>
            </FormControl>

            <AddressForm
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
                namePrefix="facility"
            />
        </>
    );

    const _loadFacilities = async (
        inputValue: string,
        callback: (arg0: any) => void,
    ) => {
        const token = await getAccessTokenSilently({});
        authorize(token)
            .get(`/facility-npi?name=${inputValue}&type=hospital`)
            .then((res) => {
                console.log('res', res.data);
                const data = res.data.map((facility: any) => ({
                    label: facility.basic.organization_name,
                    value: facility.number,
                }));
                callback(data);
            });
    };

    const loadFacilities = useCallback(debounce(_loadFacilities, 500), []);
    return (
        <MainLayout>
            <VStack
                bg={useColorModeValue('white', 'gray.900')}
                border={'1px'}
                p={8}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'start'}
            >
                <HStack width="100%">
                    <PanelHeading>Log Visit</PanelHeading>
                    <Spacer />
                    <Button onClick={demoData} colorScheme="orange">
                        Demo Data
                    </Button>
                </HStack>

                <SkeletonText isLoaded={!!client} width="100%">
                    <HStack width="100%" mt={4}>
                        <Card width="100%">
                            <CardHeader>
                                <Heading size={'md'}>
                                    Client: {client?.user?.firstName}{' '}
                                    {client?.user?.lastName}
                                </Heading>
                            </CardHeader>
                            <CardBody>
                                <Text>Email: {client?.user?.email}</Text>
                                <Text>Phone: {client?.user?.phone}</Text>
                            </CardBody>
                        </Card>
                    </HStack>
                </SkeletonText>

                <Box width="100%">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <FormControl isInvalid={!!errors.facilityNpi} mt={4}>
                            <FormLabel htmlFor="facilityNpi">
                                Hospital NPI
                            </FormLabel>
                            <Input
                                id="facilityNpi"
                                placeholder="NPI"
                                {...register('facility.npi', {
                                    required: 'This is required',
                                    minLength: {
                                        value: 10,
                                        message: 'Minimum length should be 10',
                                    },
                                })}
                            />
                            <AsyncSelect loadOptions={loadFacilities} />
                            <FormHelperText>
                                (If you have this info it would be super
                                helpful, but it's ok if you don't)
                            </FormHelperText>
                            <FormErrorMessage>
                                {errors.facilityNpi &&
                                    errors.facilityNpi.message?.toString()}
                            </FormErrorMessage>
                        </FormControl> */}

                        <FormControl
                            isInvalid={!!errors.dateOfService}
                            isRequired
                        >
                            <FormLabel htmlFor="dateOfService">
                                Date of Service
                            </FormLabel>
                            <Controller
                                name="dateOfService"
                                control={control}
                                rules={{
                                    required: 'This is required',
                                    minLength: {
                                        value: 1,
                                        message: 'Minimum length should be 1',
                                    },
                                    pattern: /\d{1,2}\/\d{1,2}\/\d{2,4}/,
                                }}
                                render={({ field }) => (
                                    <Input
                                        as={ReactInputMask}
                                        mask="**/**/****"
                                        id="dobMonth"
                                        placeholder="MM/DD/YYYY"
                                        {...field}
                                    />
                                )}
                            />

                            <FormErrorMessage>
                                {errors.dateOfService &&
                                    errors.dateOfService.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl
                            isInvalid={!!errors.visitType}
                            mt={4}
                            isRequired
                        >
                            <FormLabel htmlFor="visitType">
                                Visit Type
                            </FormLabel>
                            <Select
                                id="visitType"
                                {...register('visitType', {
                                    required: 'This is required',
                                    minLength: {
                                        value: 2,
                                        message: 'Minimum length should be 2',
                                    },
                                })}
                            >
                                {APIConstants.VISIT_TYPES.map((type) => (
                                    <optgroup label={type.groupName}>
                                        {type.options.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        ))}
                                    </optgroup>
                                ))}
                            </Select>
                            <FormErrorMessage>
                                {errors.visitType &&
                                    errors.visitType.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>

                        {durationRequiredHelper(watchVisitType) && (
                            <FormControl
                                isInvalid={!!errors.durationMinutes}
                                mt={4}
                            >
                                <FormLabel htmlFor="durationMinutes">
                                    Duration
                                </FormLabel>

                                <NumberInput id="durationMinutes">
                                    <InputGroup>
                                        <NumberInputField
                                            autoComplete="off"
                                            data-1p-ignore
                                            {...register('durationMinutes', {
                                                min: 0,
                                            })}
                                        />
                                        <InputRightElement>
                                            <Text pr={16}>mins</Text>
                                        </InputRightElement>
                                    </InputGroup>
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>

                                <FormErrorMessage>
                                    {errors.durationMinutes &&
                                        errors.durationMinutes.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                        )}

                        <FormControl
                            isInvalid={!!errors.visitType}
                            mt={4}
                            isRequired
                        >
                            <FormLabel htmlFor="visitType">
                                Was this a telehealth visit?
                            </FormLabel>
                            <RadioGroup>
                                <Stack direction="row">
                                    <Radio
                                        value="1"
                                        {...register('telehealth')}
                                    >
                                        Not a telehealth visit
                                    </Radio>
                                    <Radio
                                        value="2"
                                        {...register('telehealth')}
                                    >
                                        Audio-only telephone
                                    </Radio>
                                    <Radio
                                        value="3"
                                        {...register('telehealth')}
                                    >
                                        Audio & video communication system
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                            <FormErrorMessage>
                                {errors.visitType &&
                                    errors.visitType.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>

                        {watchTelehealth === '1' && (
                            <FormControl
                                isInvalid={!!errors.placeOfService}
                                mt={4}
                                isRequired
                            >
                                <FormLabel htmlFor="placeOfService">
                                    Place of Service
                                </FormLabel>
                                <Select
                                    id="placeOfService"
                                    {...register('placeOfService', {
                                        required: 'This is required',
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Minimum length should be 2',
                                        },
                                    })}
                                >
                                    <option
                                        value={
                                            APIConstants.FacilityTypeCode.Office
                                        }
                                    >
                                        Office/out of home
                                    </option>
                                    <option
                                        value={
                                            APIConstants.FacilityTypeCode.Home
                                        }
                                    >
                                        Client's home or residence
                                    </option>
                                    <option
                                        value={
                                            APIConstants.FacilityTypeCode
                                                .InpatientHospital
                                        }
                                    >
                                        Hospital
                                    </option>
                                    <option
                                        value={
                                            APIConstants.FacilityTypeCode
                                                .BirthingCenter
                                        }
                                    >
                                        Birthing Center
                                    </option>
                                    <option
                                        value={
                                            APIConstants.FacilityTypeCode.Other
                                        }
                                    >
                                        Other
                                    </option>
                                </Select>
                                <FormErrorMessage>
                                    {errors.placeOfService &&
                                        errors.placeOfService.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                        )}

                        {watchPlaceOfService ===
                            APIConstants.FacilityTypeCode.InpatientHospital && (
                            <HospitalForm />
                        )}

                        <FormControl mt={4}>
                            <FormLabel htmlFor="firstName">Notes</FormLabel>
                            <Textarea {...register('notes')}></Textarea>
                        </FormControl>

                        <Divider mt={4} width="100%" />

                        <input
                            type="hidden"
                            {...register('patientUserId')}
                            value={clientID}
                        />

                        <Button
                            mt={4}
                            colorScheme="teal"
                            isLoading={isSubmitting}
                            type="submit"
                        >
                            Submit
                        </Button>
                    </form>
                </Box>
            </VStack>
        </MainLayout>
    );
}
