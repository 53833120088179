import { useNavigate } from 'react-router-dom';
import {
    Button,
    Checkbox,
    Container,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    IconButton,
    Input,
    Select,
    Spacer,
    Stack,
    Text,
    Textarea,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { UseFieldArrayRemove, useFieldArray, useForm } from 'react-hook-form';
import FullScreenLayout from '../layouts/FullScreenLayout';
import Steps from './Steps';
import PanelHeading from '../components/PanelHeading';
import { FiTrash } from 'react-icons/fi';
import dayjs from 'dayjs';
import { useSaveForm } from './SaveForm';
import { ONBOARDING_ROUTES } from './Routes';
import { useFormData } from './OnboardingWrapper';
import StepActions from './components/StepActions';

export type ResumeItem = {
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    description: string;
    company?: string;
    title?: string;
    location?: string;
    current: boolean;
};

const ResumeItem = ({
    index,
    errors,
    register,
    remove,
    watch,
}: {
    index: number;
    errors: any;
    register: any;
    remove: UseFieldArrayRemove;
    watch: any;
}) => {
    const isCurrentWatch = watch(`resumeItems.${index}.current`);
    return (
        <VStack alignItems={'start'} mb={4}>
            <Flex w={'100%'}>
                <FormLabel>Entry #{index + 1}</FormLabel>
                <Spacer />
                <IconButton
                    icon={<FiTrash />}
                    aria-label="delete"
                    onClick={() => remove(index)}
                />
            </Flex>
            <FormControl>
                <FormLabel htmlFor="company">Company / Organization</FormLabel>
                <Input
                    {...register(`resumeItems.${index}.company`)}
                    placeholder="Name"
                />
                <FormHelperText>
                    Optional, leave blank if not applicable
                </FormHelperText>
            </FormControl>
            <FormControl mt={4}>
                <FormLabel htmlFor="title" w={'100px'}>
                    Title
                </FormLabel>

                <Input
                    {...register(`resumeItems.${index}.title`)}
                    placeholder="Title"
                />
                <FormHelperText>
                    Optional, leave blank if not applicable
                </FormHelperText>
            </FormControl>
            <FormControl mt={4}>
                <FormLabel htmlFor="location" w={'100px'}>
                    Location
                </FormLabel>

                <Input
                    {...register(`resumeItems.${index}.location`)}
                    placeholder="City, State"
                />
                <FormHelperText>
                    Optional, leave blank if not applicable
                </FormHelperText>
            </FormControl>
            <FormControl>
                <Checkbox {...register(`resumeItems.${index}.current`)} mt={4}>
                    I am currently working in this role
                </Checkbox>
            </FormControl>
            <HStack w={'100%'} mt={4}>
                <FormLabel htmlFor="deliveryDate" w={'100px'}>
                    From
                </FormLabel>
                <Spacer />
                <FormControl
                    isInvalid={
                        errors.resumeItems &&
                        !!errors.resumeItems[index]?.startMonth
                    }
                >
                    <Select
                        {...register(`resumeItems.${index}.startMonth`, {
                            required: 'This field is required',
                        })}
                    >
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </Select>
                    <FormErrorMessage>
                        {errors.resumeItems &&
                            errors.resumeItems[index]?.startMonth &&
                            errors.resumeItems[
                                index
                            ]?.startMonth!.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
                <FormControl
                    isInvalid={
                        errors.resumeItems &&
                        !!errors.resumeItems[index]?.startYear
                    }
                >
                    <Input
                        placeholder="YYYY"
                        {...register(`resumeItems.${index}.startYear`, {
                            required: 'This is required',
                            pattern: {
                                value: /\d{4}/,
                                message: 'Enter a valid year',
                            },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.resumeItems &&
                            errors.resumeItems[index]?.startYear &&
                            errors.resumeItems[
                                index
                            ]?.startYear!.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
            </HStack>

            <HStack mt={4} w={'100%'}>
                <FormLabel w={'100px'}>To</FormLabel>
                <Spacer />

                <FormControl
                    isInvalid={
                        errors.resumeItems &&
                        !!errors.resumeItems[index]?.endMonth
                    }
                    isDisabled={isCurrentWatch}
                >
                    <Select
                        {...register(`resumeItems.${index}.endMonth`, {
                            required: !isCurrentWatch
                                ? 'This field is required'
                                : false,
                        })}
                    >
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </Select>
                    <FormErrorMessage>
                        {errors.resumeItems &&
                            errors.resumeItems[index]?.endMonth &&
                            errors.resumeItems[
                                index
                            ]?.endMonth!.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
                <FormControl
                    isInvalid={
                        errors.resumeItems &&
                        !!errors.resumeItems[index]?.endYear
                    }
                    isDisabled={isCurrentWatch}
                >
                    <Input
                        placeholder="YYYY"
                        {...register(`resumeItems.${index}.endYear`, {
                            required: !isCurrentWatch
                                ? 'This field is required'
                                : false,
                            pattern: {
                                value: /\d{4}/,
                                message: 'Enter a valid year',
                            },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.resumeItems &&
                            errors.resumeItems[index]?.endYear &&
                            errors.resumeItems[
                                index
                            ]?.endYear!.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
            </HStack>

            <FormControl>
                <FormLabel mt={4}>Description</FormLabel>
                <FormHelperText>
                    Activities you performed as a doula or explanation of gaps
                </FormHelperText>
                <Textarea
                    mt={4}
                    w={'2xl'}
                    {...register(`resumeItems.${index}.description`)}
                ></Textarea>
            </FormControl>

            <Divider mt={4} />
        </VStack>
    );
};

export function Resume(): JSX.Element {
    const navigate = useNavigate();
    const toast = useToast();
    const { saveForm } = useSaveForm();
    const { formData } = useFormData();

    const formInstance = useForm({
        defaultValues: {
            resumeItems: formData.resumeItems || [{} as ResumeItem],
        },
    });
    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        control,
    } = formInstance;

    const validateDates = (resumeItems: ResumeItem[]) => {
        const chronologyCheck = resumeItems.some((item) => {
            if (item.current) return false;
            if (item.startYear === item.endYear) {
                if (item.startMonth > item.endMonth) {
                    return true;
                }
            }

            if (item.endYear < item.startYear) {
                return true;
            }
        });

        // Has date gaps
        const dateGapCheck = resumeItems.some((item, index) => {
            if (item.current) return false;
            if (index === 0) {
                return false;
            }

            const previousItem = resumeItems[index - 1];

            const currentDate = dayjs(
                `${item.startYear}-${item.startMonth}-01`,
            );

            const previousDate = dayjs(
                `${previousItem.endYear}-${previousItem.endMonth}-01`,
            );

            const diff = currentDate.diff(previousDate, 'month');
            // console.log('diff', diff, currentDate, previousDate);

            if (diff >= 6) {
                return true;
            }
        });

        const futureCheck = resumeItems.some((item) => {
            if (item.current) return false;
            const fromDate = dayjs(`${item.startYear}-${item.startMonth}-01`);
            const toDate = dayjs(`${item.endYear}-${item.endMonth}-01`);
            if (fromDate > dayjs() || toDate > dayjs()) {
                return true;
            }
        });

        let errorMessage = '';

        if (chronologyCheck) {
            errorMessage =
                'Please make sure the start date is before the end date.';
        }

        if (dateGapCheck) {
            errorMessage =
                'Include an explanation of any employment gaps lasting more than 6 months.';
        }

        if (futureCheck) {
            errorMessage = 'Please make sure the entries are not in the future';
        }

        if (errorMessage) {
            toast({
                title: 'Invalid Dates',
                description: errorMessage,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return false;
        }

        return true;
    };

    async function onSubmit(values: any, save: boolean = false) {
        console.log('Submitting form', values);
        if (validateDates(values.resumeItems)) {
            await saveForm(values);
            if (!save) {
                navigate(ONBOARDING_ROUTES.review);
            } else {
                toast({
                    title: 'Progress saved',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
    }

    async function demoData() {
        for (let i = 0; i < 5; i++) {
            setValue(`resumeItems.${i}.startMonth`, '1');
            setValue(`resumeItems.${i}.startYear`, (2000 + i).toString());

            setValue(`resumeItems.${i}.endMonth`, '12');
            setValue(`resumeItems.${i}.endYear`, (2000 + i).toString());

            if (i < 4) appendResumeItem();
        }
    }

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'resumeItems',
    });

    const appendResumeItem = () => {
        append({
            startMonth: '',
            startYear: '',
            endMonth: '',
            endYear: '',
            description: '',
        });
    };

    return (
        <FullScreenLayout>
            <VStack gap={8} mt={4}>
                <Steps currentStep={4} />
                <form
                    onSubmit={handleSubmit((values) => onSubmit(values, false))}
                    noValidate
                >
                    <Container
                        bg={useColorModeValue('white', 'gray.900')}
                        border={'1px'}
                        p={8}
                        borderColor={useColorModeValue('gray.200', 'gray.700')}
                        mb={6}
                        maxW={'3xl'}
                    >
                        <Button onClick={demoData} colorScheme="orange" mb={4}>
                            Demo Data
                        </Button>
                        <Stack gap={4}>
                            <PanelHeading>Resume</PanelHeading>

                            <Text>
                                We need up to five years of your most recent
                                experience as a doula. If you have gaps greater
                                than 6 months in your work history, please
                                explain them in the description field.
                            </Text>

                            <Text>
                                If you do not have five years of experience,
                                enter as many as relevant.
                            </Text>

                            <Text>
                                Start with your oldest entry and enter positions
                                chronologically.{' '}
                            </Text>

                            <Divider mt={4} mb={4} />

                            {fields.map((field, index) => (
                                <ResumeItem
                                    key={field.id}
                                    index={index}
                                    errors={errors}
                                    remove={remove}
                                    register={register}
                                    watch={formInstance.watch}
                                />
                            ))}

                            <VStack mt={4} alignItems={'start'}>
                                <Button onClick={() => appendResumeItem()}>
                                    Add Entry
                                </Button>
                            </VStack>
                        </Stack>
                    </Container>
                    <StepActions
                        formInstance={formInstance}
                        onSubmit={onSubmit}
                    />
                </form>
            </VStack>
        </FullScreenLayout>
    );
}
