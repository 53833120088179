import {
    Box,
    Spacer,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
} from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';

interface Step {
    title: string;
    description?: string;
    href: string;
}
const steps: Step[] = [
    { title: 'Welcome', description: '', href: '/onboarding' },
    { title: 'About You', href: '/onboarding/onboard' },
    { title: 'Identity', href: '/onboarding/identity' },
    { title: 'Training', href: '/onboarding/pathway' },
    { title: 'Resume', href: '/onboarding/resume' },
    // { title: 'Legal', href: '/onboarding/legal' },
    { title: 'Review', href: '/onboarding/review' },
];

interface Props {
    // Define your component's props here
    currentStep: number;
}

const Steps: React.FC<Props> = ({ currentStep }) => {
    // console.log('Index', currentStep);
    const { activeStep } = useSteps({
        index: currentStep,
        count: steps.length,
    });
    // Implement your component's logic here

    return (
        // JSX markup for your component goes here
        <Stepper index={activeStep} colorScheme="teal">
            {steps.map((step, index) => (
                <Step key={index}>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>

                    <Box flexShrink="0">
                        <StepTitle>
                            <ChakraLink as={ReactRouterLink} to={step.href}>
                                {step.title}
                            </ChakraLink>
                        </StepTitle>
                        <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                    {/* <Spacer width={'10px'} /> */}
                </Step>
            ))}
        </Stepper>
    );
};

export default Steps;
