import React from 'react';
import FullScreenLayout from '../layouts/FullScreenLayout';
import {
    Button,
    Container,
    Divider,
    Text,
    UnorderedList,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import { FiArrowRight } from 'react-icons/fi';
import Steps from './Steps';
import { useNavigate } from 'react-router-dom';
import { SecurityText } from './components/SecurityText';

interface Props {
    // Define your component's props here
}

const Overview: React.FC<Props> = ({}) => {
    const navigate = useNavigate();
    // Add your component logic here

    return (
        <FullScreenLayout>
            <VStack gap={8} mt={4}>
                <Steps currentStep={0} />
                <VStack
                    bg={useColorModeValue('white', 'gray.900')}
                    border={'1px'}
                    p={8}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}
                    mb={6}
                    w="75%"
                    gap={8}
                >
                    <Text fontSize="2xl" w={'100%'} mb={4} align="center">
                        Welcome to Loula! 🎉
                    </Text>

                    <Text align="center">
                        Hey! 🌟 Welcome to Loula, your new partner in
                        simplifying insurance billing. We're thrilled to have
                        you on board! With our user-friendly platform, you can
                        wave goodbye to paperwork stress and hello to smooth
                        sailing. Let's work together to streamline your claims,
                        freeing up your time to focus on what you do best -
                        supporting families. Cheers to a hassle-free journey
                        ahead!
                    </Text>

                    <Divider />

                    <Text as="i">Time Required: 20 minutes</Text>

                    <Text>What you'll need:</Text>
                    <VStack alignItems={'center'}>
                        <UnorderedList>
                            <li>
                                A physical copy of your ID (driver's license,
                                passport, government ID)
                            </li>
                            <li>
                                Copy of your Medi-Cal enrollment confirmation
                            </li>
                            <li>Copy of liability insurance coverage</li>
                            <li>
                                Certificate of doula training or letters of
                                recommendation
                            </li>
                            <li>Certificate of CPR training</li>
                            <li>Certificate of HIPAA training</li>
                        </UnorderedList>
                    </VStack>

                    <Container centerContent mt={4}>
                        <Button
                            colorScheme="teal"
                            rightIcon={<FiArrowRight />}
                            onClick={() => navigate('/onboarding/onboard')}
                            data-cy="get-started-button"
                        >
                            Get started
                        </Button>
                    </Container>
                </VStack>
                <SecurityText />
            </VStack>
        </FullScreenLayout>
    );
};

export default Overview;
