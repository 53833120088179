import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    ListItem,
    Stack,
    UnorderedList,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import FullScreenLayout from '../layouts/FullScreenLayout';
import Steps from './Steps';
import PanelHeading from '../components/PanelHeading';
import FileUpload from '../components/FileUpload';
import { useFormData } from './OnboardingWrapper';
import { ONBOARDING_ROUTES } from './Routes';
import { useSaveForm } from './SaveForm';
import YesNoRadioGroup from '../components/YesNoRadioGroup';
import StepActions from './components/StepActions';
import CPRCertificationUpload from './components/CPRCertificationUpload';
import HIPAACertificateUpload from './components/HIPAACertificateUpload';
import ChangePathwayAction from './components/ChangePathwayAction';

export function TrainingPathway(): JSX.Element {
    const navigate = useNavigate();
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const { saveForm } = useSaveForm();
    const { formData } = useFormData();

    const formInstance = useForm({
        defaultValues: {
            proofOfTraining: formData?.proofOfTraining,
            trainingSufficientCheck: formData?.trainingSufficientCheck,
            syllabus: formData?.syllabus,
            cprCompletion: formData?.cprCompletion,
            cprCertificate: formData?.cprCertificate,
            hipaaCompletion: formData?.hipaaCompletion,
            hipaaCertificate: formData?.hipaaCertificate,
        },
    });
    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
    } = formInstance;

    async function onSubmit(values: any, save: boolean = false) {
        console.log('Submitting form', values);

        try {
            await saveForm(values);
            if (!save) {
                navigate(ONBOARDING_ROUTES.resume);
            } else {
                toast({
                    title: 'Progress saved',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            console.error('Error saving form', err);
            toast({
                title: 'Something went wrong, please try again later.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    }

    // Training sufficient check radio group

    const trainingSufficientCheckWatch = watch('trainingSufficientCheck');
    const cprCompletionWatch = watch('cprCompletion');

    return (
        <FullScreenLayout>
            <VStack gap={8} mt={4}>
                <Steps currentStep={3} />
                <form
                    onSubmit={handleSubmit((values) => onSubmit(values, false))}
                    noValidate
                >
                    <Container
                        bg={useColorModeValue('white', 'gray.900')}
                        border={'1px'}
                        p={8}
                        borderColor={useColorModeValue('gray.200', 'gray.700')}
                        mb={6}
                        maxW={'3xl'}
                    >
                        <PanelHeading>Training Pathway</PanelHeading>
                        <Stack gap={4}>
                            <FormControl
                                id="proofOfTraining"
                                isRequired
                                isInvalid={!!errors.proofOfTraining}
                            >
                                <FormLabel>Proof of Training</FormLabel>
                                <FormHelperText mb={4}>
                                    Please upload a copy of your training
                                    certificate.
                                </FormHelperText>
                                <Controller
                                    control={control}
                                    name="proofOfTraining"
                                    rules={{
                                        required: 'This is required',
                                    }}
                                    render={({ field }) => (
                                        <FileUpload
                                            fieldName="proofOfTraining"
                                            loading={loading}
                                            setLoading={setLoading}
                                            fieldValue={field.value}
                                            maxFiles={1}
                                            formInstance={formInstance}
                                        />
                                    )}
                                />
                                <FormErrorMessage>
                                    This is required
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                mt={4}
                                isRequired
                                isInvalid={!!errors.trainingSufficientCheck}
                            >
                                <FormLabel>
                                    Does your Certificate of Completion include
                                    a minimum of 16 hours covering the following
                                    topics?
                                </FormLabel>
                                <UnorderedList fontSize={'sm'}>
                                    <ListItem>Lactation support</ListItem>
                                    <ListItem>Childbirth Education</ListItem>
                                    <ListItem>
                                        Foundations on anatomy of pregnancy and
                                        childbirth
                                    </ListItem>
                                    <ListItem>
                                        Nonmedical comfort measures, prenatal
                                        support, and labor support techniques
                                    </ListItem>
                                    <ListItem>
                                        Developing a community resource list
                                    </ListItem>
                                </UnorderedList>
                                <br />
                                <Controller
                                    control={control}
                                    name="trainingSufficientCheck"
                                    rules={{
                                        required: 'This is required',
                                    }}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <YesNoRadioGroup field={field} />
                                    )}
                                />

                                <FormErrorMessage>
                                    {errors.trainingSufficientCheck &&
                                        errors.trainingSufficientCheck.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            {trainingSufficientCheckWatch === 'no' && (
                                <FormControl
                                    isRequired
                                    isInvalid={!!errors.syllabus}
                                >
                                    <FormLabel mt={4}>
                                        Upload a copy of your training course
                                        syllabus
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        name="syllabus"
                                        rules={{
                                            required: 'This is required',
                                        }}
                                        render={({ field }) => (
                                            <FileUpload
                                                fieldName={field.name}
                                                loading={loading}
                                                setLoading={setLoading}
                                                fieldValue={field.value}
                                                formInstance={formInstance}
                                            />
                                        )}
                                    />

                                    <FormErrorMessage>
                                        {errors.syllabus &&
                                            errors.syllabus.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>
                            )}

                            <FormControl
                                mt={4}
                                isRequired
                                isInvalid={!!errors.cprCompletion}
                            >
                                <FormLabel>
                                    Have you completed a CPR certification
                                    course?
                                </FormLabel>
                                <Controller
                                    control={control}
                                    name="cprCompletion"
                                    rules={{
                                        required: 'This is required',
                                    }}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <YesNoRadioGroup field={field} />
                                    )}
                                />
                                <FormErrorMessage>
                                    {errors.cprCompletion &&
                                        errors.cprCompletion.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            {cprCompletionWatch === 'no' && (
                                <FormLabel mt={4} color={'red'}>
                                    You'll need to have completed a CPR
                                    certification course to join Loula.
                                </FormLabel>
                            )}
                            {cprCompletionWatch === 'yes' && (
                                <CPRCertificationUpload
                                    formInstance={formInstance}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                            )}

                            <HIPAACertificateUpload
                                formInstance={formInstance}
                                loading={loading}
                                setLoading={setLoading}
                            />
                        </Stack>
                    </Container>
                    <StepActions
                        formInstance={formInstance}
                        onSubmit={onSubmit}
                        leftAction={<ChangePathwayAction />}
                    />
                </form>
            </VStack>
        </FullScreenLayout>
    );
}
