import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import FileUpload from '../../components/FileUpload';
import YesNoRadioGroup from '../../components/YesNoRadioGroup';

interface Props {
    formInstance: UseFormReturn<any>;
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

const HIPAACertificateUpload: React.FC<Props> = ({
    formInstance,
    loading,
    setLoading,
}) => {
    const hipaaCompletionWatch = formInstance.watch('hipaaCompletion');
    const { control } = formInstance;
    return (
        <>
            <FormControl
                mt={4}
                isRequired
                isInvalid={!!formInstance.formState.errors.hipaaCompletion}
            >
                <FormLabel>
                    Have you completed a HIPAA training course?
                </FormLabel>
                <Controller
                    control={control}
                    name="hipaaCompletion"
                    rules={{
                        required: 'This is required',
                    }}
                    defaultValue={''}
                    render={({ field }) => <YesNoRadioGroup field={field} />}
                />
                <FormErrorMessage>
                    {formInstance.formState.errors.hipaaCompletion &&
                        formInstance.formState.errors.hipaaCompletion.message?.toString()}
                </FormErrorMessage>
            </FormControl>
            {hipaaCompletionWatch === 'no' && (
                <FormLabel mt={4} color={'red'}>
                    You'll need to have completed a HIPAA training course to
                    join Loula.
                </FormLabel>
            )}
            {hipaaCompletionWatch === 'yes' && (
                <FormControl
                    isRequired
                    isInvalid={!!formInstance.formState.errors.hipaaCertificate}
                >
                    <FormLabel mt={4}>
                        Upload a copy of your HIPAA training certificate
                    </FormLabel>
                    <Controller
                        control={formInstance.control}
                        name="hipaaCertificate"
                        rules={{
                            required: 'This is required',
                        }}
                        render={({ field }) => (
                            <FileUpload
                                fieldName={field.name}
                                fieldValue={field.value}
                                formInstance={formInstance}
                                loading={loading}
                                setLoading={setLoading}
                            />
                        )}
                    />

                    <FormErrorMessage>
                        {formInstance.formState.errors.hipaaCertificate &&
                            formInstance.formState.errors.hipaaCertificate.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
            )}
        </>
    );
};

export default HIPAACertificateUpload;
