import { Tab, TabList, Tabs } from '@chakra-ui/react';
import MainLayout from '../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { Outlet, Link as ReactRouterLink, useLocation } from 'react-router-dom';

export function Settings(): JSX.Element | null {
    const tabs = [
        {
            name: 'Security',
            path: 'settings',
        },
        {
            name: 'Payment',
            path: 'payment',
        },
        {
            name: 'Profile',
            path: 'profile',
        },
    ];
    const [tabIndex, setTabIndex] = useState(0);
    const location = useLocation();
    const { pathname } = location;
    useEffect(() => {
        const tab = pathname.split('/').slice(-1)[0];
        console.log('Tab', tab);
        setTabIndex(tabs.findIndex((t) => t.path === tab));
    });
    return (
        <MainLayout>
            <Tabs index={tabIndex} mb={4}>
                <TabList>
                    <Tab as={ReactRouterLink} to=".">
                        Security
                    </Tab>
                    <Tab as={ReactRouterLink} to="./payment">
                        Payment
                    </Tab>
                    <Tab as={ReactRouterLink} to="./profile">
                        Profile
                    </Tab>
                </TabList>
                {/* <TabPanels>
                    <TabPanel>
                        
                    </TabPanel>
                    <TabPanel>
                        <PaymentTab />
                    </TabPanel>
                </TabPanels> */}
            </Tabs>
            <Outlet />
        </MainLayout>
    );
}
