import {
    Button,
    ButtonGroup,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tab,
    TabList,
    Tabs,
    useDisclosure,
} from '@chakra-ui/react';
import MainLayout from '../layouts/MainLayout';
import {
    Link,
    Outlet,
    useLocation,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { authorize } from '../api/API';
import { getName } from '../util/Utils';
import { FiEdit2, FiUserX } from 'react-icons/fi';

export function ClientDetail(): JSX.Element | null {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const { clientID } = useParams();
    const tabs = [
        {
            name: 'Overview',
            path: 'overview',
        },
        {
            name: 'Claims',
            path: 'visits',
        },
    ];
    const [tabIndex, setTabIndex] = useState(0);
    const [client, setClient] = useState<any>(null);

    const location = useLocation();

    const { pathname } = location;
    useEffect(() => {
        const tab = pathname.split('/').slice(-1)[0];
        setTabIndex(tabs.findIndex((t) => t.path === tab));
    });

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/clients/${clientID}`)
                .then((res): void => {
                    const client = res.data;
                    console.log('client ', client);
                    setClient(res.data);
                });
        })();
    }, []);

    const deactivateClient = async () => {
        const token = await getAccessTokenSilently({});
        authorize(token)
            .get(`/clients/${clientID}/deactivate`)
            .then((res): void => {
                console.log('client ', res.data);
                navigate('/clients');
            });
    };

    const InactivateModal = ({
        client,
        handleSubmit,
    }: {
        client: any;
        handleSubmit: () => void;
    }) => {
        const { isOpen, onOpen, onClose } = useDisclosure();
        return (
            <>
                <Button
                    colorScheme="teal"
                    borderColor={'white.500'}
                    onClick={onOpen}
                >
                    <FiUserX />
                </Button>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            Mark{' '}
                            {client &&
                                getName(
                                    client.user.preferredName,
                                    client.user.firstName,
                                    client.user.lastName,
                                )}{' '}
                            as inactive?
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            You will no longer be able to log visits against
                            this client. Any billing in progress will continue,
                            but no new claims will be generated once a client is
                            inactive.
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                size={'md'}
                                variant="ghost"
                                mr={3}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                size={'md'}
                                colorScheme="teal"
                                onClick={() => {
                                    handleSubmit();
                                    onClose();
                                }}
                            >
                                Mark Inactive
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        );
    };

    return (
        <MainLayout
            backButtonRoute={'/clients'}
            headerText={
                client &&
                getName(
                    client.user.preferredName,
                    client.user.firstName,
                    client.user.lastName,
                )
            }
        >
            <Tabs index={tabIndex} mb={4}>
                <HStack borderBottom={'2px solid'} borderColor={'inherit'}>
                    <TabList w="100%" borderBottom={0}>
                        <Tab as={Link} to="./overview">
                            Overview
                        </Tab>
                        <Tab as={Link} to="./visits">
                            Visits
                        </Tab>
                    </TabList>
                    <ButtonGroup size={'sm'} isAttached mb={2}>
                        <Button
                            colorScheme="teal"
                            onClick={() =>
                                navigate(`/clients/${client.user.id}/edit`)
                            }
                        >
                            <FiEdit2 />
                        </Button>
                        <InactivateModal
                            client={client}
                            handleSubmit={deactivateClient}
                        />
                    </ButtonGroup>
                </HStack>
            </Tabs>
            <Outlet context={{ client }} />
        </MainLayout>
    );
}

export function useClient() {
    return useOutletContext<any>();
}
