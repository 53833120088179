import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authorize } from '../api/API';
import {
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Flex,
    FormLabel,
    HStack,
    Icon,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Tr,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import FullScreenLayout from '../layouts/FullScreenLayout';
import Steps from './Steps';
import PanelHeading from '../components/PanelHeading';
import { useFormData } from './OnboardingWrapper';
import humanizeString from 'humanize-string';
import { ResumeItem } from './Resume';
import dayjs from 'dayjs';
import { FiEdit2 } from 'react-icons/fi';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import { ONBOARDING_ROUTES } from './Routes';
import _ from 'lodash';
import { SecurityText } from './components/SecurityText';

const ReviewSection = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <HStack
                gap={8}
                justifyContent={'space-between'}
                alignItems={'start'}
            >
                {children}
            </HStack>
            <Divider mb={4} />
        </>
    );
};

const ReviewSectionHeading = ({
    children,
    route,
    formData,
}: {
    children: React.ReactNode;
    route: string;
    formData?: any;
}) => {
    const dataMissing = () => {
        switch (route) {
            case ONBOARDING_ROUTES.onboard:
                return (
                    !formData.firstName ||
                    !formData.lastName ||
                    !formData.address1 ||
                    !formData.city ||
                    !formData.state ||
                    !formData.zip ||
                    !formData.npi ||
                    !formData.tin ||
                    !formData.mediCalEnrollmentFile ||
                    !formData.proofOfLiabilityInsurance
                );
            case ONBOARDING_ROUTES.identity:
                return !formData.identityVerified;
            case ONBOARDING_ROUTES.pathway:
                return (
                    (!formData.proofOfTraining &&
                        !formData.proofOfExperience) ||
                    !formData.hipaaCertificate ||
                    !formData.cprCertificate
                );
            case ONBOARDING_ROUTES.resume:
                return !formData.resumeItems;
            default:
                return false;
        }
    };

    return (
        <VStack>
            <Flex
                maxW="sm"
                alignContent={'start'}
                alignItems={'center'}
                gap={2}
            >
                <Text fontSize="lg">{children}</Text>
                <ChakraLink as={ReactRouterLink} to={route}>
                    <Icon as={FiEdit2} />
                </ChakraLink>
            </Flex>
            {dataMissing() && <Badge colorScheme="orange">INCOMPLETE</Badge>}
        </VStack>
    );
};

const ReviewSectionContent = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box maxW={'2xl'} w={'2xl'}>
            {children}
        </Box>
    );
};

export function Review(): JSX.Element {
    const { getAccessTokenSilently } = useAuth0();

    const token = getAccessTokenSilently();
    const navigate = useNavigate();
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const { formData } = useFormData();

    const onboardFn = async (token: string) => {
        return authorize(token).get(`/providers/onboard`);
    };

    const validateForm = () => {
        const requiredKeys = [
            'firstName',
            'lastName',
            'address1',
            'city',
            'state',
            'zip',
            'npi',
            'tin',
            'mediCalEnrollmentFile',
            'proofOfLiabilityInsurance',
            'resumeItems',
            'identityVerified',
            'hipaaCertificate',
        ];

        const missingKeys = requiredKeys.some((key) => !formData[key]);

        if (missingKeys) {
            toast({
                title: 'Please fill out all required fields',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return false;
        }

        return true;
    };

    async function onSubmit() {
        console.log('Submitting form');

        if (!validateForm()) {
            return false;
        }

        setLoading(true);

        await onboardFn(await token)
            .then(() => {
                console.log('Onboarding form submitted');
                navigate('/onboarding/complete');
            })
            .catch((err) => {
                console.error('Error creating client', err);
                if (err.response.status === 400) {
                    toast({
                        title: 'Something went wrong. Please reach out to support@joinloula.com if this issue persists.',
                        description: 'Client already exists',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                    return;
                }

                toast({
                    title: 'Something went wrong. Please reach out to support@joinloula.com if this issue persists.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (_.isEmpty(formData)) {
            console.log('No form data, redirecting to /onboarding');
            navigate('/onboarding');
        } else {
            console.log('Form data:', formData);
        }
    }, []);

    return (
        <FullScreenLayout>
            <VStack gap={8} mt={4}>
                <Steps currentStep={5} />
                <Container
                    bg={useColorModeValue('white', 'gray.900')}
                    border={'1px'}
                    p={8}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    gap={8}
                    maxW="5xl"
                >
                    <PanelHeading>Review</PanelHeading>

                    <Container maxW={'6xl'} m={0} mt={8}>
                        <Stack direction={'column'}>
                            <ReviewSection>
                                <ReviewSectionHeading
                                    route={ONBOARDING_ROUTES.onboard}
                                    formData={formData}
                                >
                                    About You
                                </ReviewSectionHeading>
                                <ReviewSectionContent>
                                    <Table variant={'unstyled'}>
                                        <Tbody>
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        First Name
                                                    </FormLabel>
                                                </Td>
                                                <Td>{formData.firstName}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        Last Name
                                                    </FormLabel>
                                                </Td>
                                                <Td>{formData.lastName}</Td>
                                            </Tr>
                                            {formData.preferredName && (
                                                <Tr>
                                                    <Td>
                                                        <FormLabel fontSize="sm">
                                                            Preferred Name
                                                        </FormLabel>
                                                    </Td>
                                                    <Td>
                                                        {formData.preferredName}
                                                    </Td>
                                                </Tr>
                                            )}
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        Address
                                                    </FormLabel>
                                                </Td>
                                                <Td>
                                                    <Text>
                                                        {formData.address1}
                                                    </Text>
                                                    <Text>
                                                        {formData.address2}
                                                    </Text>
                                                    <Text>
                                                        {formData.city},{' '}
                                                        {formData.state}{' '}
                                                        {formData.zip}
                                                    </Text>
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        Gender
                                                    </FormLabel>
                                                </Td>
                                                <Td>
                                                    {humanizeString(
                                                        formData?.gender ?? '',
                                                    )}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        Pronouns
                                                    </FormLabel>
                                                </Td>
                                                <Td>{formData.pronouns}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        Phone
                                                    </FormLabel>
                                                </Td>
                                                <Td>{formData.phone}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        Date of birth
                                                    </FormLabel>
                                                </Td>
                                                <Td>{formData.dob}</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </ReviewSectionContent>
                            </ReviewSection>
                            <ReviewSection>
                                <ReviewSectionHeading
                                    route={
                                        ONBOARDING_ROUTES.onboard +
                                        '#identifiers'
                                    }
                                    formData={formData}
                                >
                                    Identifiers
                                </ReviewSectionHeading>
                                <ReviewSectionContent>
                                    <Table variant={'unstyled'} w="100%">
                                        <Tbody>
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        NPI
                                                    </FormLabel>
                                                </Td>
                                                <Td>{formData.npi}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        TIN
                                                    </FormLabel>
                                                </Td>
                                                <Td>{formData.tin}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        Proof of Medi-Cal
                                                        Enrollment
                                                    </FormLabel>
                                                </Td>
                                                <Td>
                                                    {(formData.mediCalEnrollmentFile &&
                                                        formData
                                                            .mediCalEnrollmentFile[0]
                                                            .originalFileName) ??
                                                        'No file uploaded'}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        Proof of Liability
                                                        Insurance
                                                    </FormLabel>
                                                </Td>
                                                <Td>
                                                    {(formData.proofOfLiabilityInsurance &&
                                                        formData
                                                            .proofOfLiabilityInsurance[0]
                                                            .originalFileName) ??
                                                        'No file uploaded'}
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </ReviewSectionContent>
                            </ReviewSection>
                            <ReviewSection>
                                <ReviewSectionHeading
                                    route={ONBOARDING_ROUTES.identity}
                                    formData={formData}
                                >
                                    Identity
                                </ReviewSectionHeading>
                                <ReviewSectionContent>
                                    <Table variant={'unstyled'} w="100%">
                                        <Tbody>
                                            <Tr>
                                                <Td>
                                                    <FormLabel fontSize="sm">
                                                        Identity Verification
                                                    </FormLabel>
                                                </Td>
                                                <Td>
                                                    {formData.identityVerified ? (
                                                        <Text>
                                                            Verification
                                                            Submitted
                                                        </Text>
                                                    ) : (
                                                        <Text>
                                                            Verification
                                                            Incomplete
                                                        </Text>
                                                    )}
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </ReviewSectionContent>
                            </ReviewSection>
                            <ReviewSection>
                                <ReviewSectionHeading
                                    route={ONBOARDING_ROUTES.pathway}
                                    formData={formData}
                                >
                                    Training
                                </ReviewSectionHeading>
                                <ReviewSectionContent>
                                    <Table variant={'unstyled'} w="100%">
                                        <Tbody>
                                            {!!formData.proofOfTraining ? (
                                                <>
                                                    <Tr>
                                                        <Td>
                                                            <FormLabel fontSize="sm">
                                                                Pathway
                                                            </FormLabel>
                                                        </Td>
                                                        <Td>Training</Td>
                                                    </Tr>
                                                    {Array.isArray(
                                                        formData.proofOfTraining,
                                                    ) &&
                                                        (
                                                            formData.proofOfTraining as any[]
                                                        ).map((file, i) => (
                                                            <Tr key={i}>
                                                                <Td>
                                                                    <FormLabel fontSize="sm">
                                                                        Proof of
                                                                        training
                                                                    </FormLabel>
                                                                </Td>
                                                                <Td>
                                                                    {
                                                                        file.originalFileName
                                                                    }
                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                </>
                                            ) : (
                                                <>
                                                    <Tr>
                                                        <Td>
                                                            <FormLabel fontSize="sm">
                                                                Pathway
                                                            </FormLabel>
                                                        </Td>
                                                        <Td>Experience</Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td>
                                                            <FormLabel fontSize="sm">
                                                                Proof of
                                                                Experience
                                                            </FormLabel>
                                                        </Td>
                                                        <Td>
                                                            {Array.isArray(
                                                                formData.proofOfExperience,
                                                            ) &&
                                                                (
                                                                    formData.proofOfExperience as any[]
                                                                ).map(
                                                                    (file) => (
                                                                        <Text>
                                                                            {
                                                                                file.originalFileName
                                                                            }
                                                                        </Text>
                                                                    ),
                                                                )}
                                                        </Td>
                                                    </Tr>
                                                </>
                                            )}
                                        </Tbody>
                                    </Table>
                                </ReviewSectionContent>
                            </ReviewSection>
                            <ReviewSection>
                                <ReviewSectionHeading
                                    route={ONBOARDING_ROUTES.resume}
                                    formData={formData}
                                >
                                    Resume
                                </ReviewSectionHeading>
                                <ReviewSectionContent>
                                    <Table variant={'unstyled'} w="100%">
                                        <Tbody>
                                            {Array.isArray(
                                                formData.resumeItems,
                                            ) &&
                                                (
                                                    formData.resumeItems as ResumeItem[]
                                                ).map((item) => (
                                                    <Tr>
                                                        <Td>
                                                            <Text>
                                                                {dayjs(
                                                                    `${item.startYear}-${item.startMonth}-01`,
                                                                ).format(
                                                                    'MMMM YYYY',
                                                                )}{' '}
                                                                -{' '}
                                                                {item.endYear
                                                                    ? dayjs(
                                                                          `${item.endYear}-${item.endMonth}-01`,
                                                                      ).format(
                                                                          'MMMM YYYY',
                                                                      )
                                                                    : 'Present'}
                                                            </Text>
                                                            <Text>
                                                                {item.company}
                                                                {item.title
                                                                    ? `| ${item.title}`
                                                                    : ''}
                                                                {item.location
                                                                    ? `| ${item.location}`
                                                                    : ''}
                                                            </Text>
                                                        </Td>
                                                        <Td>
                                                            <Text>
                                                                {
                                                                    item.description
                                                                }
                                                            </Text>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                        </Tbody>
                                    </Table>
                                </ReviewSectionContent>
                            </ReviewSection>
                        </Stack>
                    </Container>
                </Container>
                <VStack w="100%" p="0">
                    <Button
                        onClick={onSubmit}
                        colorScheme="teal"
                        isLoading={loading}
                        size={'lg'}
                    >
                        Submit Application
                    </Button>
                </VStack>
                <SecurityText />
            </VStack>
        </FullScreenLayout>
    );
}
