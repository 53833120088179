/**
 * <Route path="/onboarding" element={<OnboardingWrapper />}>
                        <Route index element={<Overview />} />
                        <Route path="onboard" element={<Onboard />} />
                        <Route path="identity" element={<Identity />} />
                        <Route path="pathway" element={<SelectPathway />} />
                        <Route
                            path="training-pathway"
                            element={<TrainingPathway />}
                        />
                        <Route
                            path="experience-pathway"
                            element={<ExperiencePathway />}
                        />
                        <Route path="resume" element={<Resume />} />

                        <Route
                            path="complete"
                            element={<OnboardingComplete />}
                        />
                    </Route>
 */
export const ONBOARDING_ROUTES = {
    overview: '/onboarding',
    onboard: '/onboarding/onboard',
    identity: '/onboarding/identity',
    pathway: '/onboarding/pathway',
    trainingPathway: '/onboarding/training-pathway',
    experiencePathway: '/onboarding/experience-pathway',
    resume: '/onboarding/resume',
    review: '/onboarding/review',
    complete: '/onboarding/complete',
};
