import React from 'react';
import FullScreenLayout from '../layouts/FullScreenLayout';
import {
    Center,
    Container,
    Image,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import Steps from './Steps';

interface Props {
    // Define your component's props here
}

const OnboardingComplete: React.FC<Props> = ({}) => {
    // Add your component logic here

    return (
        <FullScreenLayout>
            <VStack gap={8} mt={4}>
                <VStack
                    bg={useColorModeValue('white', 'gray.900')}
                    border={'1px'}
                    p={8}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}
                    mb={6}
                    w="75%"
                >
                    <Text fontSize="2xl" w={'100%'} mb={4} align="center">
                        We're reviewing your information
                    </Text>

                    <Container centerContent boxSize="sm">
                        <Center>
                            <Image
                                src="/images/stork.gif"
                                alt="Onboarding complete"
                            />
                        </Center>
                    </Container>

                    <Text align="center">
                        Thanks! We're glad to have you on board. Our team is now
                        reviewing the information you provided and getting the
                        platform ready for you to use. We appreciate your
                        patience and look forward to helping you streamline your
                        billing process. Keep an eye on your inbox for further
                        instructions. <br />
                        <br />
                        Thanks again for choosing Loula!
                    </Text>
                </VStack>
            </VStack>
        </FullScreenLayout>
    );
};

export default OnboardingComplete;
