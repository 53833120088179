import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authorize } from '../api/API';
import {
    Button,
    Container,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import FullScreenLayout from '../layouts/FullScreenLayout';
import Steps from './Steps';
import { ONBOARDING_ROUTES } from './Routes';
import PanelHeading from '../components/PanelHeading';
import { useSaveForm } from './SaveForm';
import { useFormData } from './OnboardingWrapper';

export function Identity(): JSX.Element {
    const { getAccessTokenSilently } = useAuth0();

    const token = getAccessTokenSilently();
    const navigate = useNavigate();
    const [stripeURL, setStripeURL] = useState<string>('');
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const { saveForm } = useSaveForm();
    const { formData } = useFormData();

    const [verificationComplete] = useState(
        searchParams.get('verification_complete') === 'true' ||
            formData?.identityVerified,
    );

    const launchStripe = () => {
        setLoading(true);
        console.log('Launching Stripe', stripeURL);
        window.location.href = stripeURL;
    };

    const onSubmit = async () => {
        await saveForm({ identityVerified: true });
        navigate(ONBOARDING_ROUTES.pathway);
    };

    useEffect(() => {
        console.log('Identity re-rendered');
        const fetchData = async () => {
            authorize(await token)
                .get('/providers/stripe/verification-session')
                .then((res) => {
                    console.log('Stripe URL', res.data);
                    setStripeURL(res.data.url);
                });
        };
        if (!formData?.identityVerified) {
            fetchData();
        }
    }, []);

    return (
        <FullScreenLayout>
            <VStack gap={8} mt={4}>
                <Steps currentStep={2} />
                <Container
                    bg={useColorModeValue('white', 'gray.900')}
                    border={'1px'}
                    p={8}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    mb={6}
                    maxW={'3xl'}
                >
                    <PanelHeading>Verify Your Identity</PanelHeading>
                    <VStack gap={8}>
                        {verificationComplete ? (
                            <>
                                <Text mt={4}>
                                    Thanks, we'll reach out if we need anything
                                    further to verify your identity.{' '}
                                </Text>
                                <Button onClick={onSubmit} colorScheme="teal">
                                    Next
                                </Button>
                            </>
                        ) : (
                            <>
                                {' '}
                                <Text>We need to verify your identity.</Text>
                                <Button
                                    onClick={() => {
                                        launchStripe();
                                    }}
                                    isLoading={loading}
                                >
                                    Start identity verification
                                </Button>
                            </>
                        )}
                    </VStack>
                </Container>
            </VStack>
        </FullScreenLayout>
    );
}
