import { authorize } from '../api/API';
import { useAuth0 } from '@auth0/auth0-react';

export const useSaveForm = () => {
    const { getAccessTokenSilently } = useAuth0();
    const token = getAccessTokenSilently();

    const saveForm = async (data: any) => {
        return authorize(await token).post('/providers/onboard/form', data);
    };

    const retrieveForm = () => {};

    return { saveForm, retrieveForm };
};
