import { To, useLocation, useNavigate } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    ListItem,
    Text,
    UnorderedList,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import FullScreenLayout from '../layouts/FullScreenLayout';
import Steps from './Steps';
import PanelHeading from '../components/PanelHeading';
import { useFormData } from './OnboardingWrapper';
import { useSaveForm } from './SaveForm';
import { ONBOARDING_ROUTES } from './Routes';
import { useEffect } from 'react';

export function SelectPathway(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const { formData } = useFormData();
    const { saveForm } = useSaveForm();

    if (location.state?.reset) {
        // reset form data
        formData.proofOfTraining = null;
        formData.proofOfExperience = null;
        formData.trainingSufficientCheck = null;
        formData.cprCertificate = null;
        formData.cprCompletion = null;
        formData.hipaaCompletion = null;
    }

    const save = async (route: To) => {
        if (location.state?.reset) {
            await saveForm(formData);
        }
        navigate(route);
    };

    useEffect(() => {
        if (!location.state?.reset) {
            if (formData.proofOfTraining) {
                navigate(ONBOARDING_ROUTES.trainingPathway);
                return;
            }
            if (formData.proofOfExperience) {
                navigate(ONBOARDING_ROUTES.experiencePathway);
                return;
            }
        }
    }, []);

    return (
        <FullScreenLayout>
            <VStack gap={8} mt={4}>
                <Steps currentStep={3} />
                <Container
                    bg={useColorModeValue('white', 'gray.900')}
                    border={'1px'}
                    p={8}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    mb={6}
                    maxW="3xl"
                    gap={8}
                >
                    <PanelHeading>Select Your Pathway</PanelHeading>
                    <Text mt={4}>
                        Choose the pathway you originally selected when
                        enrolling in Medi-Cal.
                    </Text>
                    <VStack gap={8} mt={8}>
                        <Card
                            w={'100%'}
                            _hover={{
                                bg: 'teal.400',
                                color: 'white',
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                save(ONBOARDING_ROUTES.trainingPathway)
                            }
                        >
                            <CardHeader>
                                <Text fontSize="lg">Training Pathway</Text>
                            </CardHeader>
                            <CardBody>
                                <UnorderedList>
                                    <ListItem>
                                        To qualify through this pathway, you
                                        need to have completed a training
                                        program.
                                    </ListItem>
                                    <ListItem>
                                        The certificate of completion should
                                        include details such as completed hours
                                        and covered topics. If this information
                                        is not included in the certificate, a
                                        syllabus from the training program is
                                        required.
                                    </ListItem>
                                    <ListItem>
                                        Additionally, you must provide evidence
                                        of supporting at least three births.
                                    </ListItem>
                                </UnorderedList>
                            </CardBody>
                        </Card>

                        <Card
                            w={'100%'}
                            _hover={{
                                bg: 'teal.400',
                                color: 'white',
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                save(ONBOARDING_ROUTES.experiencePathway)
                            }
                        >
                            <CardHeader>
                                <Text fontSize="lg">Experience Pathway</Text>
                            </CardHeader>
                            <CardBody>
                                <UnorderedList>
                                    <ListItem>
                                        This pathway focuses on the doula's
                                        practical experience.
                                    </ListItem>
                                    <ListItem>
                                        You need to demonstrate your experience
                                        by providing contact information for
                                        where you have practiced.
                                    </ListItem>
                                    <ListItem>
                                        They also need to submit three written
                                        client testimonial letters or
                                        professional letters of recommendation.
                                    </ListItem>
                                    <ListItem>
                                        These letters must come from specified
                                        professionals or organizations within
                                        the last seven years. At least one
                                        letter must be from a licensed provider,
                                        a community-based organization, or an
                                        enrolled doula.
                                    </ListItem>
                                </UnorderedList>
                            </CardBody>
                        </Card>
                    </VStack>
                </Container>
            </VStack>
        </FullScreenLayout>
    );
}
