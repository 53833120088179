import {
    Button,
    Card,
    CardBody,
    HStack,
    Spacer,
    Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { authorize } from '../../api/API';
import VisitsTable from '../../claims/components/VisitsTable';
import { useAuth0 } from '@auth0/auth0-react';
import { FiPlus, FiPlusCircle } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';

const VisitsPanel: React.FC = () => {
    const { clientID } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [visits, setVisits] = useState<any[] | undefined>(undefined);
    useEffect(() => {
        // please fix this!
        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/clients/${clientID}/visits`)
                .then((res) => {
                    const visits = res.data;
                    setVisits(visits);
                });
        })();
    }, []);
    return (
        <Stack gap={4}>
            <HStack>
                <Spacer />
                <Button
                    leftIcon={<FiPlus />}
                    onClick={() => navigate(`/clients/${clientID}/visits/bulk`)}
                    colorScheme="gray"
                >
                    Bulk Log
                </Button>
                <Button
                    leftIcon={<FiPlusCircle />}
                    onClick={() =>
                        navigate(`/clients/${clientID}/visits/create`)
                    }
                    colorScheme="teal"
                    variant="solid"
                >
                    Log Visit
                </Button>
            </HStack>
            <Card>
                <CardBody>
                    <VisitsTable visits={visits} data-hj-suppress />
                </CardBody>
            </Card>
        </Stack>
    );
};

export default VisitsPanel;
