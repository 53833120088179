import {
    Button,
    ButtonGroup,
    UseRadioProps,
    useColorModeValue,
    useId,
    useRadio,
    useRadioGroup,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

interface RadioCardProps extends UseRadioProps {
    label: string;
}

const RadioCard: FC<RadioCardProps> = (props) => {
    const id = useId(props.id, `transformControlsModeIcons`);
    const { getInputProps, getCheckboxProps } = useRadio({ id, ...props });

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <>
            <Button
                aria-label={props.label}
                as="label"
                cursor={'pointer'}
                htmlFor={input.id}
                border={'1px'}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                {...checkbox}
                _checked={{
                    bg: 'teal.600',
                    color: 'white',
                    borderColor: 'teal.600',
                }}
                _invalid={{
                    borderColor: 'red.500',
                }}
            >
                {props.label}
            </Button>
            <input {...input} />
        </>
    );
};

interface YesNoRadioGroupProps {
    // Define your component props here
    field: ControllerRenderProps<any, any>;
}

const YesNoRadioGroup: React.FC<YesNoRadioGroupProps> = (props) => {
    const { getRootProps, getRadioProps } = useRadioGroup({
        ...props.field,
    });

    return (
        // JSX code for your component's UI
        <ButtonGroup {...getRootProps()} isAttached>
            <RadioCard
                {...getRadioProps({
                    value: 'yes',
                })}
                label="Yes"
            />
            <RadioCard {...getRadioProps({ value: 'no' })} label="No" />
        </ButtonGroup>
    );
};

export default YesNoRadioGroup;
