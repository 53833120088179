import { FacilityTypeCode, VISIT_TYPES } from '../api/APIConstants';

/**
 * Returns the full name based on the provided name parts.
 * If a preferred name is available, it will be used; otherwise, the first name will be used.
 * The last name will always be included in the full name.
 * @param preferredName - The preferred name (optional).
 * @param firstName - The first name.
 * @param lastName - The last name.
 * @returns The full name.
 */
export const getName = (
    preferredName?: string,
    firstName?: string,
    lastName?: string,
): string => {
    return `${preferredName ? preferredName : firstName} ${lastName}`;
};

/**
 * Checks if the onboarding process is complete for a provider profile.
 * @todo: Use the onboarding_status column in the database to determine if the onboarding process is complete!!!
 * @param providerProfile - The provider profile object.
 * @returns A boolean indicating whether the onboarding process is complete.
 */
export const onboardingComplete = (providerProfile: any): boolean => {
    console.log('checking onboarding status', providerProfile);
    if (providerProfile == null) {
        console.log('Provider profile is null');
        return false;
    }

    return providerProfile.onboardingStatus === 'approved';
};

/**
 * Converts minutes to a readable format of hours and minutes.
 * @param minutes - The number of minutes to convert.
 * @returns A string representation of the converted time in the format "X hour(s) and Y minute(s)".
 */
export function convertMinutesToReadable(minutes: number) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hour(s) and ${remainingMinutes} minute(s)`;
}

/**
 * Retrieves the label for a given visit type.
 * @param visitType - The visit type to search for.
 * @returns The label of the visit type, or an empty string if not found.
 */
export function getVisitTypeLabel(visitType: string): string {
    console.log('Searching for visit type: ', visitType);
    const search = VISIT_TYPES.flatMap((group) => {
        return group.options.find((option) => option.value === visitType);
    });
    if (search.length > 0 && search[0]?.label) return search[0].label;
    return '';
}

/**
 * Retrieves the label for a given place of service code.
 * @param placeOfService - The place of service code.
 * @returns The label corresponding to the place of service code, or an empty string if the code is null or not found.
 */
export function getPlaceOfServiceLabel(placeOfService: number): string {
    if (placeOfService == null) return '';

    const search = Object.entries(FacilityTypeCode).filter(([k, v]) => {
        if (Number(v) == placeOfService) return k;
    });
    return search.length > 0 ? search[0][0] : '';
}
