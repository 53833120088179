import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { authorize } from '../api/API';
import MainLayout from '../layouts/MainLayout';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    Input,
    Select,
    Spacer,
    Switch,
    Text,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { faker } from '@faker-js/faker/locale/en_US';
import { PatientRelationshipToInsuredCodeAll } from '../api/APIConstants';
import ReactInputMask from 'react-input-mask';
import dayjs from 'dayjs';
import { usePlacesWidget } from 'react-google-autocomplete';
import Loading from '../components/Loading';
import isEmpty from 'lodash.isempty';
import PanelHeading from '../components/PanelHeading';
import humanizeString from 'humanize-string';

/**
 * This is an example of a generic "Resource Display" page.
 * It uses the Medplum `<ResourceTable>` component to display a resource.
 * @returns A React component that displays a resource.
 */
export function EditClient(): JSX.Element {
    const { getAccessTokenSilently } = useAuth0();
    // const [clients, setClients] = useState<any[] | undefined>(undefined);
    const { clientID } = useParams();
    const [payers, setPayers] = useState<any[]>([]);
    const [client, setClient] = useState<any>({});

    const token = getAccessTokenSilently();
    const navigate = useNavigate();
    const toast = useToast();

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        setValue,
        watch,
        getValues,
        control,
        reset,
    } = useForm();

    const { ref } = usePlacesWidget<HTMLInputElement>({
        apiKey: 'AIzaSyATDhJYAZxBIMl8IJBEQXtCvXwwDeVvdFU',
        onPlaceSelected: (place) => {
            const {
                streetNumber,
                streetName,
                subpremise,
                city,
                state,
                postalCode,
            } = parsePlaceResponse(place);
            if (ref.current)
                ref.current.value = `${streetNumber} ${streetName}`;
            setValue('address1', `${streetNumber} ${streetName}`);
            setValue('address2', subpremise);
            setValue('city', city);
            setValue('state', state);
            setValue('zip', postalCode);
        },
        options: {
            componentRestrictions: { country: 'us' },
            types: ['address'],
            inputAutocompleteValue: '',
        },
    });

    const insuranceSame = watch('insuranceSame');

    const resetFormValues = (client: any) => {
        return {
            firstName: client.user?.firstName,
            lastName: client.user?.lastName,
            pronouns: client.user?.pronouns,
            email: client.user?.email,
            phone: client.user?.phone,
            address1: client.user?.address1,
            address2: client.user?.address2,
            city: client.user?.city,
            state: client.user?.state,
            zip: client.user?.zip,
            dob: dayjs(client.user?.dob).format('MM/DD/YYYY'),
            deliveryDate: dayjs(client.deliveryDate).format('MM/DD/YYYY'),
            insurance: {
                firstName:
                    client.patientInsurance &&
                    client.patientInsurance[0]?.firstName,
                lastName:
                    client.patientInsurance &&
                    client.patientInsurance[0]?.lastName,
                address1:
                    client.patientInsurance &&
                    client.patientInsurance[0]?.address1,
                address2:
                    client.patientInsurance &&
                    client.patientInsurance[0]?.address2,
                city:
                    client.patientInsurance && client.patientInsurance[0]?.city,
                state:
                    client.patientInsurance &&
                    client.patientInsurance[0]?.state,
                zip: client.patientInsurance && client.patientInsurance[0]?.zip,

                memberId:
                    client.patientInsurance &&
                    client.patientInsurance[0]?.memberId,
                groupNumber:
                    client.patientInsurance &&
                    client.patientInsurance[0]?.groupNumber,
                dob: dayjs(client.patientInsurance[0]?.dob).format(
                    'MM/DD/YYYY',
                ),
                payerId:
                    client.patientInsurance &&
                    client.patientInsurance[0]?.payerId,
                patientRelationshipToSubscriber:
                    client.patientInsurance &&
                    client.patientInsurance[0]?.patientRelationshipToSubscriber,
                sex: client.patientInsurance && client.patientInsurance[0]?.sex,
            },
        };
    };

    const getClient = async (clientID: string) => {
        console.log('getting client', clientID);
        const token = await getAccessTokenSilently();
        return authorize(token)
            .get(`/clients/${clientID}`)
            .then((res): void => {
                const client = res.data;
                console.log('client ', client);
                setClient(client);
                return client;
            });
    };

    const getPayers = async () => {
        const token = await getAccessTokenSilently();
        return authorize(token)
            .get(`/clients/payers`)
            .then((res): void => {
                const payers = res.data;
                console.log('payers ', payers);
                // setClients(clients);
                setPayers(payers);
            });
    };

    const parsePlaceResponse = (place: any) => {
        console.log('place', place);
        const { address_components } = place;
        let streetNumber = '';
        let streetName = '';
        let subpremise;
        let city = '';
        let state = '';
        let postalCode = '';

        address_components.forEach((component: any) => {
            const { types, long_name } = component;
            if (types.includes('street_number')) {
                streetNumber = long_name;
            } else if (types.includes('route')) {
                streetName = long_name;
            } else if (types.includes('locality')) {
                city = long_name;
            } else if (types.includes('administrative_area_level_1')) {
                state = long_name;
            } else if (types.includes('postal_code')) {
                postalCode = long_name;
            } else if (types.includes('subpremise')) {
                subpremise = long_name;
            }
        });

        return {
            streetNumber,
            streetName,
            subpremise,
            city,
            state,
            postalCode,
        };
    };

    const updateClient = async (token: string, updatedClient: any) => {
        updatedClient.insurance.groupNumber =
            updatedClient.insurance.groupNumber.toUpperCase();
        return authorize(token).patch(
            `/clients/${client.user.id}`,
            updatedClient,
        );
    };

    async function onSubmit(values: any) {
        console.log('Submitting form', values);

        await updateClient(await token, values)
            .then(() => {
                console.log('Client edited');
                navigate(`/clients/${client.user.id}/overview`);
            })
            .catch((err) => {
                console.error('Error creating client', err);
                if (err.response.status === 400) {
                    toast({
                        title: 'Error updating client',
                        description: 'Client already exists',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                    return;
                }

                toast({
                    title: 'Error updating client',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    const demoData = () => {
        const randomFirstName = faker.person.firstName('female');
        const randomLastName = faker.person.lastName();
        const randomPhone = faker.phone.number();
        const randomDOB = faker.date.birthdate();
        const randomState = faker.location.state({ abbreviated: true });
        const randomAddress = faker.location.streetAddress();
        const randomEmail = faker.internet.exampleEmail({
            firstName: randomFirstName,
            lastName: randomLastName,
        });

        setValue('firstName', randomFirstName);
        setValue('lastName', randomLastName);
        setValue('pronouns', 'she/her');
        setValue('email', randomEmail);
        setValue('phone', randomPhone);
        setValue('address1', randomAddress);
        if (ref.current) {
            // Check if ref.current is not undefined
            ref.current.value = randomAddress;
        }
        setValue('address2', faker.location.secondaryAddress());
        setValue('city', faker.location.city());
        setValue('state', randomState);
        setValue(
            'zip',
            faker.location.zipCode({
                state: randomState,
                format: '#####',
            }),
        );
        setValue('dobMonth', randomDOB.getMonth());
        setValue('dobDay', randomDOB.getDate() + 1);
        setValue('dobYear', randomDOB.getFullYear());
        setValue(
            'deliveryDate',
            dayjs(faker.date.future()).format('MM/DD/YYYY'),
        );

        setValue('insurance.memberId', faker.number.int());
        setValue('insurance.groupNumber', faker.string.alphanumeric(8));
    };

    const updateInsuranceSame = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fields = [
            'firstName',
            'lastName',
            'address1',
            'address2',
            'city',
            'state',
            'zip',
            'dobYear',
            'dobMonth',
            'dobDay',
        ];
        if (e.target.checked) {
            fields.map((f) => setValue(`insurance.${f}`, getValues(f)));
        } else {
            fields.map((f) => setValue(`insurance.${f}`, ''));
        }
    };

    useEffect(() => {
        if (clientID !== undefined) {
            getClient(clientID).then((client) => {
                reset(resetFormValues(client));
            });
        }
    }, [clientID]);

    useEffect(() => {
        getPayers();
    }, []);

    return (
        <MainLayout backButtonRoute={`/clients/${client.user?.id}/overview`}>
            {isEmpty(client) ? (
                <Loading />
            ) : (
                <VStack
                    bg={useColorModeValue('white', 'gray.900')}
                    border={'1px'}
                    p={8}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}
                >
                    <HStack width="100%">
                        <PanelHeading>Edit Client</PanelHeading>
                        <Spacer />
                        <Button onClick={demoData} colorScheme="orange">
                            Demo Data
                        </Button>
                    </HStack>

                    <Box w="75%">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl isInvalid={!!errors.firstName}>
                                <FormLabel htmlFor="firstName">
                                    First name
                                </FormLabel>
                                <Input
                                    id="firstName"
                                    placeholder="First name"
                                    {...register('firstName', {
                                        required: 'This is required',
                                        minLength: {
                                            value: 1,
                                            message:
                                                'Minimum length should be 1',
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.firstName &&
                                        errors.firstName.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!errors.lastName} mt={4}>
                                <FormLabel htmlFor="lastName">
                                    Last name
                                </FormLabel>
                                <Input
                                    id="lastName"
                                    placeholder="Last name"
                                    {...register('lastName', {
                                        required: 'This is required',
                                        minLength: {
                                            value: 1,
                                            message:
                                                'Minimum length should be 1',
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.lastName &&
                                        errors.lastName.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!errors.pronouns} mt={4}>
                                <FormLabel htmlFor="pronouns">
                                    Pronouns
                                </FormLabel>
                                <Input
                                    id="pronouns"
                                    placeholder="she/her, he/him, they/them"
                                    {...register('pronouns', {
                                        required: 'This is required',
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Minimum length should be 2',
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.lastName &&
                                        errors.lastName.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!errors.email} mt={4}>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Input
                                    id="email"
                                    placeholder="Email"
                                    {...register('email', {
                                        required: 'This is required',
                                        minLength: {
                                            value: 4,
                                            message:
                                                'Minimum length should be 4',
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.email &&
                                        errors.email.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!errors.phone} mt={4}>
                                <FormLabel htmlFor="phone">Phone</FormLabel>
                                <Input
                                    id="phone"
                                    placeholder="Phone"
                                    {...register('phone', {
                                        minLength: {
                                            value: 4,
                                            message:
                                                'Minimum length should be 4',
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.phone &&
                                        errors.phone.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!errors.address1} mt={4}>
                                <FormLabel htmlFor="address1">
                                    Address
                                </FormLabel>

                                <Controller
                                    control={control}
                                    name="address1"
                                    render={() => (
                                        <Input
                                            id="address1"
                                            placeholder="Address"
                                            ref={ref}
                                        />
                                    )}
                                />
                                <FormErrorMessage>
                                    {errors.address1 &&
                                        errors.address1.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!errors.address2} mt={4}>
                                <FormLabel htmlFor="address2">
                                    Address 2
                                </FormLabel>
                                <Input
                                    id="address2"
                                    placeholder="Address"
                                    {...register('address2')}
                                />
                                <FormErrorMessage>
                                    {errors.address2 &&
                                        errors.address2.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            {['city', 'state', 'zip'].map((field) => (
                                <FormControl isInvalid={!!errors[field]} mt={4}>
                                    <FormLabel
                                        htmlFor={field}
                                        textTransform={'capitalize'}
                                    >
                                        {field}
                                    </FormLabel>
                                    <Input
                                        id={field}
                                        placeholder={field}
                                        textTransform={'capitalize'}
                                        {...register(field)}
                                    />
                                    <FormErrorMessage>
                                        {errors[field] &&
                                            errors[field]?.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>
                            ))}

                            <FormControl isInvalid={!!errors.dob} mt={4}>
                                <FormLabel htmlFor="dob">
                                    Date of birth
                                </FormLabel>
                                <Input
                                    as={ReactInputMask}
                                    mask="**/**/****"
                                    id="dob"
                                    placeholder="MM/DD/YYYY"
                                    {...register(`dob`, {
                                        pattern: /\d{1,2}\/\d{1,2}\/\d{2,4}/,
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.dob &&
                                        errors.dob.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                isInvalid={!!errors.deliveryDate}
                                mt={4}
                            >
                                <FormLabel htmlFor="deliveryDate">
                                    Expected Delivery Date
                                </FormLabel>
                                <Input
                                    as={ReactInputMask}
                                    mask="**/**/****"
                                    id="deliveryDate"
                                    placeholder="MM/DD/YYYY"
                                    {...register(`deliveryDate`, {
                                        pattern: /\d{1,2}\/\d{1,2}\/\d{2,4}/,
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.deliveryDate &&
                                        errors.deliveryDate.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <Divider mt={4} mb={4} />

                            <section>
                                <Text fontSize={'lg'} mb="lg">
                                    Insurance
                                </Text>

                                <FormControl
                                    display="flex"
                                    alignItems="center"
                                    mt="4"
                                >
                                    <FormLabel htmlFor="email-alerts" mb="0">
                                        The client is the subscriber
                                    </FormLabel>
                                    <Switch
                                        {...register('insuranceSame', {
                                            onChange: (e) =>
                                                updateInsuranceSame(e),
                                        })}
                                        id="insurance-same"
                                    />
                                </FormControl>

                                {['firstName', 'lastName'].map((field) => (
                                    <FormControl
                                        isInvalid={
                                            !!errors[`insurance.${field}`]
                                        }
                                        mt={4}
                                    >
                                        <FormLabel
                                            htmlFor={`insurance.${field}`}
                                            textTransform={'capitalize'}
                                        >
                                            {humanizeString(field)}
                                        </FormLabel>
                                        <Input
                                            id={`insurance.${field}`}
                                            placeholder={field
                                                .replace('_', ' ')
                                                .replace(/\d/, '')}
                                            textTransform={'capitalize'}
                                            disabled={insuranceSame}
                                            {...register(`insurance.${field}`)}
                                        />
                                        <FormErrorMessage>
                                            {errors[`insurance.${field}`] &&
                                                errors[
                                                    `insurance.${field}`
                                                ]?.message?.toString()}
                                        </FormErrorMessage>
                                    </FormControl>
                                ))}

                                <FormControl
                                    isInvalid={!!errors[`insurance.sex`]}
                                    mt={4}
                                    isRequired
                                >
                                    <FormLabel
                                        htmlFor={`insurance.sex`}
                                        textTransform={'capitalize'}
                                    >
                                        Sex
                                    </FormLabel>
                                    <Select {...register('insurance.sex')}>
                                        <option value="female">Female</option>
                                        <option value="male">Male</option>
                                        <option value="other">Other</option>
                                    </Select>
                                    <FormHelperText>
                                        We respect all gender identities however
                                        insurance requires us to collect sex
                                        that is on file with the insurance plan.
                                    </FormHelperText>
                                    <FormErrorMessage>
                                        {errors[`insurance.sex`] &&
                                            errors[
                                                `insurance.sex`
                                            ]?.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>

                                {[
                                    'address1',
                                    'address2',
                                    'city',
                                    'state',
                                    'zip',
                                ].map((field) => (
                                    <FormControl
                                        isInvalid={
                                            !!errors[`insurance.${field}`]
                                        }
                                        mt={4}
                                    >
                                        <FormLabel
                                            htmlFor={`insurance.${field}`}
                                            textTransform={'capitalize'}
                                        >
                                            {field
                                                .replace('_', ' ')
                                                .replace(/\d/, '')}
                                        </FormLabel>
                                        <Input
                                            id={`insurance.${field}`}
                                            placeholder={field
                                                .replace('_', ' ')
                                                .replace(/\d/, '')}
                                            textTransform={'capitalize'}
                                            disabled={insuranceSame}
                                            {...register(`insurance.${field}`)}
                                        />
                                        <FormErrorMessage>
                                            {errors[`insurance.${field}`] &&
                                                errors[
                                                    `insurance.${field}`
                                                ]?.message?.toString()}
                                        </FormErrorMessage>
                                    </FormControl>
                                ))}

                                <FormControl
                                    isInvalid={
                                        errors.insurance &&
                                        !!(errors.insurance as any).dob
                                    }
                                    mt={4}
                                >
                                    <FormLabel htmlFor="insurance.dob">
                                        Date of birth
                                    </FormLabel>
                                    <Input
                                        as={ReactInputMask}
                                        mask="**/**/****"
                                        id="insurance.dob"
                                        placeholder="MM/DD/YYYY"
                                        {...register(`insurance.dob`, {
                                            pattern:
                                                /\d{1,2}\/\d{1,2}\/\d{2,4}/,
                                        })}
                                    />

                                    <FormErrorMessage>
                                        {errors[`insurance.dob`] &&
                                            errors[
                                                `insurance.dob`
                                            ]?.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    isInvalid={!!errors['insurance.payerId']}
                                    mt={4}
                                    isRequired
                                >
                                    <FormLabel htmlFor="insurance.payerId">
                                        Insurance Plan
                                    </FormLabel>
                                    <Controller
                                        name="insurance.payerId"
                                        control={control}
                                        render={({ field }) => (
                                            <Select {...field}>
                                                <option value="">
                                                    Select a payer
                                                </option>
                                                {payers.map((payer) => (
                                                    <option
                                                        key={payer.stediPayerId}
                                                        value={
                                                            payer.stediPayerId
                                                        }
                                                    >
                                                        {payer.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    <FormErrorMessage>
                                        {errors[`insurance.payerId`] &&
                                            errors[
                                                `insurance.payerId`
                                            ]?.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    isInvalid={!!errors['insurance.memberId']}
                                    mt={4}
                                >
                                    <FormLabel htmlFor="memberId">
                                        Member ID
                                    </FormLabel>
                                    <Input
                                        id="memberId"
                                        placeholder="Member ID"
                                        {...register('insurance.memberId')}
                                    />
                                    <FormErrorMessage>
                                        {errors[`insurance.memberId`] &&
                                            errors[
                                                `insurance.memberId`
                                            ]?.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    isInvalid={
                                        !!errors['insurance.groupNumber']
                                    }
                                    mt={4}
                                >
                                    <FormLabel htmlFor="groupNumber">
                                        Group Number
                                    </FormLabel>
                                    <Input
                                        id="groupNumber"
                                        placeholder="Group Number"
                                        {...register('insurance.groupNumber')}
                                    />
                                    <FormErrorMessage>
                                        {errors[`insurance.groupNumber`] &&
                                            errors[
                                                `insurance.groupNumber`
                                            ]?.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>
                            </section>

                            <input
                                type="hidden"
                                {...register(
                                    'insurance.patientRelationshipToSubscriber',
                                    {
                                        value: insuranceSame
                                            ? PatientRelationshipToInsuredCodeAll.Self
                                            : PatientRelationshipToInsuredCodeAll.Unknown,
                                    },
                                )}
                            />

                            <Button
                                mt={4}
                                colorScheme="teal"
                                isLoading={isSubmitting}
                                type="submit"
                            >
                                Submit
                            </Button>
                        </form>
                    </Box>
                </VStack>
            )}
        </MainLayout>
    );
}
