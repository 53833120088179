import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { authorize } from '../api/API';
import MainLayout from '../layouts/MainLayout';
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    Heading,
    Input,
    SkeletonText,
    Spacer,
    Text,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { faker } from '@faker-js/faker';
import InputMask from 'react-input-mask';
import dayjs from 'dayjs';
import PanelHeading from '../components/PanelHeading';

/**
 * This is an example of a generic "Resource Display" page.
 * It uses the Medplum `<ResourceTable>` component to display a resource.
 * @returns A React component that displays a resource.
 */
export function CreateClaim(): JSX.Element {
    const { getAccessTokenSilently } = useAuth0();
    // const [claims, setClaims] = useState<any[] | undefined>(undefined);

    const token = getAccessTokenSilently();
    const navigate = useNavigate();
    const toast = useToast();
    const { clientID } = useParams();
    const [client, setClient] = useState<any | undefined>(undefined);

    useEffect(() => {
        // please fix this!
        if (token == null) {
            return;
        }

        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/clients/${clientID}`)
                .then((res): void => {
                    const client = res.data;
                    console.log('client ', client);
                    setClient(res.data);
                });
        })();
    }, []);

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        setValue,
        control,
    } = useForm();

    const { fields, append } = useFieldArray({
        control,
        name: 'serviceLines',
    });

    const appendServiceLine = () => {
        append({ procedureCode: '', quantity: 1 });
    };

    const createClaim = async (token: string, client: any) => {
        return authorize(token).post(`/claims`, client);
    };

    useEffect(() => {
        console.log(errors);
    }, [errors]);

    async function onSubmit(values: any) {
        console.log('Submitting form', values);

        await createClaim(await token, values)
            .then(() => {
                console.log('Claim created');
                navigate('/claims?created=true');
            })
            .catch((err) => {
                console.error('Error creating claim', err);
                if (err.response.status === 400) {
                    toast({
                        title: 'Error creating claim',
                        description: 'Claim already exists',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                    return;
                }

                toast({
                    title: 'Error creating claim. Please contact support@joinloula.com for assistance.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    async function demoData() {
        const randomEmail = faker.internet.email();
        const randomFirstName = faker.person.firstName();
        const randomLastName = faker.person.lastName();
        const randomPhone = faker.phone.number();

        setValue('firstName', randomFirstName);
        setValue('lastName', randomLastName);
        setValue('pronouns', 'she/her');
        setValue('email', randomEmail);
        setValue('phone', randomPhone);

        for (let i = 0; i < 5; i++) {
            appendServiceLine();

            setValue(
                `serviceLines.${i}.dateOfService`,
                dayjs(faker.date.past()).format('MM/DD/YYYY'),
            );
            setValue(`serviceLines.${i}.procedureCode`, 'Z1032');
            setValue(`serviceLines.${i}.quantity`, 1);
            setValue(
                `serviceLines.${i}.charges`,
                faker.number.float({
                    min: 10,
                    max: 200,
                    fractionDigits: 2,
                }),
            );
        }
    }

    return (
        <MainLayout>
            <VStack
                bg={useColorModeValue('white', 'gray.900')}
                border={'1px'}
                p={8}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'start'}
            >
                <HStack width="100%">
                    <PanelHeading>Create Claim</PanelHeading>
                    <Spacer />
                    <Button onClick={demoData} colorScheme="orange">
                        Demo Data
                    </Button>
                </HStack>

                <SkeletonText isLoaded={!!client} width="100%">
                    <HStack width="100%" mt={4}>
                        <Card width="100%">
                            <CardHeader>
                                <Heading size={'md'}>
                                    Client: {client?.user?.firstName}{' '}
                                    {client?.user?.lastName}
                                </Heading>
                            </CardHeader>
                            <CardBody>
                                <Text>Email: {client?.user?.email}</Text>
                                <Text>Phone: {client?.user?.phone}</Text>
                            </CardBody>
                        </Card>
                    </HStack>
                </SkeletonText>

                <Box width="100%">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Divider mt={4} width="100%" />

                        <HStack mt={4} width="100%">
                            <Heading size="md">Services</Heading>
                            <Spacer />
                            <Button onClick={() => appendServiceLine()}>
                                Add Service Line
                            </Button>
                        </HStack>

                        {fields.map((field, index) => (
                            <HStack mt={4} key={`serviceLineRow${index}`}>
                                <FormLabel
                                    htmlFor="dobMonth"
                                    mt={index === 0 ? 9 : 4}
                                >
                                    {index + 1}.
                                </FormLabel>

                                <FormControl
                                    isInvalid={
                                        !!errors[
                                            `serviceLines.${index}.dateOfService`
                                        ]
                                    }
                                >
                                    {index === 0 && (
                                        <FormLabel>Date of Service</FormLabel>
                                    )}
                                    <Controller
                                        name={`serviceLines.${index}.dateOfService`}
                                        control={control}
                                        rules={{
                                            required: 'This is required',
                                            minLength: {
                                                value: 1,
                                                message:
                                                    'Minimum length should be 1',
                                            },
                                            pattern:
                                                /\d{1,2}\/\d{1,2}\/\d{2,4}/,
                                        }}
                                        render={({ field }) => (
                                            <Input
                                                as={InputMask}
                                                mask="**/**/****"
                                                id="dobMonth"
                                                placeholder="MM/DD/YYYY"
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormControl>

                                <FormControl>
                                    {index === 0 && <FormLabel>Code</FormLabel>}
                                    <Input
                                        id="dobMonth"
                                        placeholder="Z1032"
                                        {...register(
                                            `serviceLines.${index}.procedureCode`,
                                            {
                                                // required: 'This is required',
                                                minLength: {
                                                    value: 1,
                                                    message:
                                                        'Minimum length should be 1',
                                                },
                                            },
                                        )}
                                    />
                                </FormControl>

                                <FormControl>
                                    {index === 0 && <FormLabel>Qty</FormLabel>}
                                    <Input
                                        id="dobDay"
                                        placeholder="1"
                                        {...register(
                                            `serviceLines.${index}.quantity`,
                                            {
                                                // required: 'This is required',
                                                minLength: {
                                                    value: 1,
                                                    message:
                                                        'Minimum length should be 1',
                                                },
                                            },
                                        )}
                                    />
                                </FormControl>

                                <FormControl>
                                    {index === 0 && (
                                        <FormLabel>Charges</FormLabel>
                                    )}
                                    <Input
                                        id="dobYear"
                                        placeholder="10"
                                        {...register(
                                            `serviceLines.${index}.charges`,
                                            {
                                                // required: 'This is required',
                                            },
                                        )}
                                    />
                                </FormControl>
                            </HStack>
                        ))}

                        <input
                            type="hidden"
                            {...register('clientUserId')}
                            value={clientID}
                        />

                        <Button
                            mt={4}
                            colorScheme="teal"
                            isLoading={isSubmitting}
                            type="submit"
                        >
                            Submit
                        </Button>
                    </form>
                </Box>
            </VStack>
        </MainLayout>
    );
}
