'use client';

import { useAuth0 } from '@auth0/auth0-react';
import {
    Avatar,
    Badge,
    Box,
    Button,
    Flex,
    FlexProps,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Table,
    Tbody,
    Td,
    Text,
    Tr,
    VStack,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { FiBell, FiChevronDown, FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useProvider } from '../components/PrivateRoute';

interface MobileProps extends FlexProps {
    backButtonRoute: string;
    headerText?: string;
}

function DebugModal() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { provider } = useProvider();
    const { user } = useAuth0();
    return (
        <>
            <MenuItem onClick={onOpen}>Debug</MenuItem>
            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Debug</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing="4">
                            <Table>
                                <Tbody>
                                    <Tr>
                                        <Td>User ID</Td>
                                        <Td> {provider.user.id}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Auth0 ID</Td>
                                        <Td> {user?.sub}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Email</Td>
                                        <Td> {provider.user.email}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Name</Td>
                                        <Td>
                                            {provider.user.firstName}{' '}
                                            {provider.user.lastName}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

const MobileNav = ({ backButtonRoute, headerText, ...rest }: MobileProps) => {
    const { user, logout } = useAuth0();
    const navigate = useNavigate();
    // const { provider } = useProvider();

    return (
        <Flex
            ml={{ base: 0 }}
            bg={useColorModeValue('white', 'gray.900')}
            px={{ base: 4, md: 4 }}
            height="20"
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            alignItems="center"
        >
            <IconButton
                display={{
                    base: 'none',
                    md: !!backButtonRoute ? 'flex' : 'none',
                }}
                onClick={() => navigate(backButtonRoute)}
                variant="outline"
                aria-label="back button"
                icon={<FiArrowLeft />}
            />
            <Text
                display={{
                    base: 'none',
                    md: !!backButtonRoute ? 'flex' : 'none',
                }}
                fontSize="xl"
                ml={4}
            >
                {headerText}
            </Text>
            <Spacer />
            <Flex
                ml={{ base: 0, md: 60 }}
                height="20"
                alignItems="center"
                bg={useColorModeValue('white', 'gray.900')}
                borderBottomWidth="1px"
                borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
                justifyContent={{ base: 'space-between', md: 'flex-end' }}
                {...rest}
            >
                <Text
                    display={{ base: 'flex', md: 'none' }}
                    fontSize="2xl"
                    fontFamily="monospace"
                    fontWeight="bold"
                >
                    Loula
                </Text>

                <HStack spacing={{ base: '0', md: '6' }}>
                    <IconButton
                        size="lg"
                        variant="ghost"
                        aria-label="open menu"
                        icon={<FiBell />}
                    />
                    <Flex alignItems={'center'}>
                        <Menu>
                            <MenuButton
                                py={2}
                                transition="all 0.3s"
                                _focus={{ boxShadow: 'none' }}
                            >
                                <HStack>
                                    <Avatar
                                        size={'sm'}
                                        src={
                                            'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                                        }
                                    />
                                    <VStack
                                        display={{ base: 'none', md: 'flex' }}
                                        alignItems="flex-start"
                                        spacing="1px"
                                        ml="2"
                                    >
                                        <Text fontSize="sm">{user?.name}</Text>
                                        <Text
                                            fontSize="xs"
                                            color="gray.600"
                                        ></Text>
                                    </VStack>
                                    <Box display={{ base: 'none', md: 'flex' }}>
                                        <FiChevronDown />
                                    </Box>
                                </HStack>
                            </MenuButton>
                            <MenuList
                                bg={useColorModeValue('white', 'gray.900')}
                                borderColor={useColorModeValue(
                                    'gray.200',
                                    'gray.700',
                                )}
                            >
                                <DebugModal />
                                <MenuItem
                                    onClick={() =>
                                        logout({
                                            logoutParams: {
                                                returnTo:
                                                    window.location.origin,
                                            },
                                        })
                                    }
                                >
                                    Sign out
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </HStack>
            </Flex>
        </Flex>
    );
};

const FullScreenLayout = ({
    children,
    backButtonRoute = '',
    headerText,
}: {
    children: React.ReactNode;
    backButtonRoute?: string;
    headerText?: string;
}) => {
    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <Box w={{ base: 'full', md: 60 }} pos="fixed" h="full">
                <Flex
                    h="20"
                    alignItems="center"
                    mx="8"
                    justifyContent="space-between"
                >
                    <Text
                        fontSize="2xl"
                        fontFamily="monospace"
                        fontWeight="bold"
                    >
                        Loula
                    </Text>
                    {import.meta.env.DEV ||
                        (import.meta.env.VITE_VERCEL_ENV === 'preview' && (
                            <Badge colorScheme="red">Staging</Badge>
                        ))}
                </Flex>
            </Box>
            {/* mobilenav */}

            <MobileNav
                backButtonRoute={backButtonRoute}
                headerText={headerText}
            />
            <Box ml={{ base: 0 }} p="4">
                {children}
            </Box>
        </Box>
    );
};

export default FullScreenLayout;
