import { Button, HStack, Spacer } from '@chakra-ui/react';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

interface Props {
    formInstance: UseFormReturn<any>;
    onSubmit: (data: any, save: boolean) => void;
    isNextDisabled?: boolean;
    leftAction?: React.ReactNode;
}

const StepActions: React.FC<Props> = ({
    formInstance,
    onSubmit,
    isNextDisabled = false,
    leftAction,
}) => {
    // Implement your component logic here

    const handleSave = () => {
        formInstance.handleSubmit((data) => {
            console.log('data', data);
            onSubmit(data, true);
        })();
    };

    return (
        <HStack w={'100%'} justifyContent={'end'}>
            {leftAction && (
                <>
                    {leftAction}
                    <Spacer />
                </>
            )}
            <Button onClick={handleSave} colorScheme="gray">
                Save
            </Button>
            <Button
                type="submit"
                colorScheme="teal"
                isLoading={formInstance.formState.isSubmitting}
                isDisabled={isNextDisabled}
            >
                Next
            </Button>
        </HStack>
    );
};

export default StepActions;
