import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

interface Props {
    formInstance: UseFormReturn<any>;
}

const DOB: React.FC<Props> = ({ formInstance }) => {
    const {
        control,
        formState: { errors },
    } = formInstance;
    return (
        <FormControl isInvalid={!!errors.dob} mt={4}>
            <FormLabel htmlFor="dob">Date of birth</FormLabel>

            <Controller
                name="dob"
                control={control}
                rules={{
                    required: 'This is required',
                    pattern: /\d{1,2}\/\d{1,2}\/\d{2,4}/,
                    validate: {
                        validDate: (value: any) => {
                            const isValid = dayjs(
                                value,
                                'MM/DD/YYYY',
                                true,
                            ).isValid();
                            const isFuture = dayjs(value, 'MM/DD/YYYY').isAfter(
                                dayjs(),
                            );
                            if (!isValid || isFuture) {
                                return 'Enter a valid date';
                            }
                        },
                    },
                }}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                render={({ field: { ref, ...field } }) => (
                    <Input
                        as={ReactInputMask}
                        mask="**/**/****"
                        id="dob"
                        placeholder="MM/DD/YYYY"
                        {...field}
                        inputRef={ref}
                    />
                )}
            />
            <FormErrorMessage>
                {errors.dob && errors.dob.message?.toString()}
            </FormErrorMessage>
        </FormControl>
    );
};

export default DOB;
