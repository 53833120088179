import {
    Badge,
    Skeleton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import humanizeString from 'humanize-string';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import { getName } from '../../util/Utils';

interface Props {
    // Define your component props here
    visits?: any[];
}

const VisitsTable: React.FC<Props> = ({ visits }) => {
    const getVisitType = (visitType: string) => {
        // look up value form enum
        return humanizeString(visitType);
    };

    return (
        <Skeleton isLoaded={visits !== undefined}>
            <TableContainer width={'100%'}>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Ref #</Th>
                            <Th>Name</Th>
                            <Th>Date of Service</Th>
                            <Th>Visit Type</Th>
                            <Th>Status</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {visits?.map((visit: any, index: number) => (
                            <Tr key={`visit${index}`}>
                                <Td>
                                    <Text as="kbd">
                                        <ChakraLink
                                            as={ReactRouterLink}
                                            to={`/visits/${visit.id}`}
                                        >
                                            {visit.code}
                                        </ChakraLink>
                                    </Text>
                                </Td>
                                <Td>
                                    <ChakraLink
                                        as={ReactRouterLink}
                                        to={`/visits/${visit.id}`}
                                    >
                                        {getName(
                                            visit.patient?.user.preferredName,
                                            visit.patient?.user.firstName,
                                            visit.patient?.user.lastName,
                                        )}
                                    </ChakraLink>
                                </Td>
                                <Td>
                                    {dayjs(visit.dateOfService).format(
                                        'MM/DD/YYYY',
                                    )}
                                </Td>
                                <Td>{getVisitType(visit.visitType)}</Td>
                                <Td>
                                    <Badge>DRAFT</Badge>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Skeleton>
    );
};

export default VisitsTable;
