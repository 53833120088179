import {
    Outlet,
    useLocation,
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { onboardingComplete } from '../util/Utils';
import { authorize } from '../api/API';
import Loading from './Loading';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

const PrivateRoute = () => {
    const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, user } =
        useAuth0();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [provider, setProvider] = useState<any>({});
    const { pathname } = useLocation();

    if (isAuthenticated) {
        // Has the users email been verified?

        const checkEmailVerified = async () => {
            return new Promise((resolve, reject) => {
                if (user && !user.email_verified) {
                    navigate('/verify-email');
                    reject('verification required');
                }

                resolve(true);
            });
        };
        const getProfileFn = (token: string) =>
            authorize(token)
                .get(`/providers/me`)
                .then((res): void => {
                    console.log('got provider data', res.data);
                    setProvider(res.data);
                    return res.data;
                });

        const checkOnboardingStatus = async () => {
            const token = await getAccessTokenSilently();
            const providerProfile = (await getProfileFn(token)) as any;

            // set datalayer variables
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                email: providerProfile?.user.email,
            });
            window.dataLayer.push({
                event: 'userConfigured',
            });
            if (
                !pathname.includes('/onboarding/') &&
                !onboardingComplete(providerProfile)
            ) {
                console.log('onboarding is not complete');
                navigate('/onboarding');
                return;
            }
        };

        useEffect(() => {
            checkEmailVerified()
                .then(checkOnboardingStatus)
                .then(() => setLoading(false))
                .catch((err) => {
                    console.error(err);
                    if (err !== 'verification required') {
                        navigate('/error');
                    }
                });
        }, []);

        if (!loading) return <Outlet context={{ provider } satisfies any} />;

        return <Loading />;
    } else {
        loginWithRedirect();
    }
};

export default PrivateRoute;

export function useProvider() {
    return useOutletContext<any>();
}
