import {
    Box,
    Card,
    CardBody,
    HStack,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    Table,
    Tbody,
    Td,
    Tr,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useClient } from '../ClientDetail';
import humanizeString from 'humanize-string';

interface Props {}

const OverviewPanel: React.FC<Props> = () => {
    const { client } = useClient();
    // @todo: move to own component
    return (
        <>
            {/* <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" /> */}
            <HStack spacing={'24px'} alignItems={'stretch'}>
                <Box flex="1">
                    <Card>
                        <CardBody>
                            <Table variant={'unstyled'}>
                                <Tbody>
                                    <Tr>
                                        <Td width={4} fontWeight={'medium'}>
                                            Name
                                        </Td>
                                        <Td data-hj-suppress>
                                            {client?.user.firstName}{' '}
                                            {client?.user.preferredName &&
                                                `(
                                            ${client?.user.preferredName}) `}
                                            {client?.user.lastName}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td
                                            width={4}
                                            fontWeight={'medium'}
                                            border={'none'}
                                        >
                                            Pronouns
                                        </Td>
                                        <Td>{client?.user?.pronouns}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td width={4} fontWeight={'medium'}>
                                            Email
                                        </Td>
                                        <Td data-hj-suppress>
                                            {client?.user.email}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td width={4} fontWeight={'medium'}>
                                            Phone
                                        </Td>
                                        <Td data-hj-suppress>
                                            {client?.user.phone}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td width={4} fontWeight={'medium'}>
                                            Gender
                                        </Td>
                                        <Td data-hj-suppress>
                                            {client?.user.gender &&
                                                humanizeString(
                                                    client.user.gender,
                                                )}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td width={4} fontWeight={'medium'}>
                                            Address
                                        </Td>
                                        <Td data-hj-suppress>
                                            {client?.user.address1}
                                            <br />
                                            {client?.user.address2}
                                            <br />
                                            {client?.user.city},{' '}
                                            {client?.user.state}{' '}
                                            {client?.user.zip}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Box>
                <VStack alignItems={'stretch'}>
                    <Card width={'150px'} flex="1">
                        <CardBody>
                            <Stat>
                                <StatLabel>Visits Logged</StatLabel>
                                <StatNumber>12</StatNumber>
                                <StatHelpText>Feb 12 - Feb 28</StatHelpText>
                            </Stat>
                        </CardBody>
                    </Card>
                </VStack>
            </HStack>
        </>
    );
};

export default OverviewPanel;
