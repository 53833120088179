import { useAuth0 } from '@auth0/auth0-react';
import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
// import { ProtectedRoute } from './components/ProtectedRoute';
import { HomePage } from './pages/HomePage';
import { Clients } from './clients/Clients';
import { CreateClient } from './clients/CreateClient';
import { Onboard } from './onboarding/Onboard';
import { Claims } from './claims/Claims';
import { CreateClaim } from './claims/CreateClaim';
import { ComingSoon } from './pages/ComingSoon';
import { ErrorPage } from './pages/ErrorPage';
import { ClientDetail } from './clients/ClientDetail';
import { Settings } from './profile/Settings';
import { CreateVisit } from './visits/CreateVisit';
import { Visits } from './visits/Visits';
import Overview from './onboarding/Overview';
import OnboardingComplete from './onboarding/OnboardingComplete';
import { BulkCreateVisits } from './visits/BulkCreateVisits';
import PrivateRoute from './components/PrivateRoute';
import Loading from './components/Loading';
import { PaymentTab } from './profile/settings/PaymentTab';
import SecurityTab from './profile/settings/SecurityTab';
import VisitsPanel from './clients/detail/VisitsPanel';
import OverviewPanel from './clients/detail/OverviewPanel';
import Payments from './payments/Payments';
import ProfileTab from './profile/settings/ProfileTab';
import { EditClient } from './clients/EditClient';
import { VisitDetail } from './visits/VisitDetail';
import { SelectPathway } from './onboarding/SelectPathway';
import { Identity } from './onboarding/Identity';
import { TrainingPathway } from './onboarding/TrainingPathway';
import { ExperiencePathway } from './onboarding/ExperiencePathway';
import { Resume } from './onboarding/Resume';
import { OnboardingWrapper } from './onboarding/OnboardingWrapper';
import { ONBOARDING_ROUTES } from './onboarding/Routes';
import { Review } from './onboarding/Review';
import { VerifyEmail } from './pages/VerifyEmail';

const useRouteLogging = () => {
    const location = useLocation();

    useEffect(() => {
        console.log(`Navigated to ${location.pathname}`);
    }, [location]);
};

export function App(): JSX.Element | null {
    useRouteLogging();

    const { isLoading } = useAuth0();

    if (isLoading) {
        return (
            <div className="page-layout">
                <Loading />
            </div>
        );
    }

    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route element={<PrivateRoute />}>
                    <Route path="/" element={<HomePage />} />

                    {/* Clients */}
                    <Route path="/clients" element={<Clients />} />
                    <Route path="/clients/:clientID" element={<ClientDetail />}>
                        <Route
                            path="overview"
                            index
                            element={<OverviewPanel />}
                        />
                        <Route path="visits" element={<VisitsPanel />} />
                    </Route>
                    <Route
                        path="/clients/:clientID/edit"
                        element={<EditClient />}
                    />
                    <Route
                        path="/clients/:clientID/visits/create"
                        element={<CreateVisit />}
                    />
                    <Route
                        path="/clients/:clientID/visits/bulk"
                        element={<BulkCreateVisits />}
                    />
                    <Route path="/clients/create" element={<CreateClient />} />

                    {/* Claims */}
                    <Route path="/claims" element={<Claims />} />
                    <Route
                        path="/claims/:clientID/create"
                        element={<CreateClaim />}
                    />

                    <Route path="/visits" element={<Visits />} />

                    <Route path="/visits/:visitID" element={<VisitDetail />} />

                    <Route path="/onboarding" element={<OnboardingWrapper />}>
                        <Route index element={<Overview />} />
                        <Route path="onboard" element={<Onboard />} />
                        <Route
                            path={ONBOARDING_ROUTES.identity}
                            element={<Identity />}
                        />
                        <Route path="pathway" element={<SelectPathway />} />
                        <Route
                            path="training-pathway"
                            element={<TrainingPathway />}
                        />
                        <Route
                            path="experience-pathway"
                            element={<ExperiencePathway />}
                        />
                        <Route path="resume" element={<Resume />} />

                        <Route
                            path={ONBOARDING_ROUTES.review}
                            element={<Review />}
                        />

                        <Route
                            path="complete"
                            element={<OnboardingComplete />}
                        />
                    </Route>
                    <Route path="/settings" element={<Settings />}>
                        <Route index element={<SecurityTab />} />
                        <Route path="payment" element={<PaymentTab />} />
                        <Route path="profile" element={<ProfileTab />} />
                    </Route>
                    <Route path="/payments" element={<Payments />} />
                </Route>
                <Route path="/tbd" element={<ComingSoon />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/error" element={<ErrorPage />} />

                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Suspense>
    );
}
