import {
    Center,
    Flex,
    Heading,
    Link,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { authorize } from '../api/API';
import { useAuth0 } from '@auth0/auth0-react';

export function VerifyEmail(): JSX.Element {
    const { getAccessTokenSilently, user, logout } = useAuth0();
    const toast = useToast();

    const triggerVerificationEmail = async () => {
        console.log('Triggering verify email');
        const token = await getAccessTokenSilently();
        authorize(token)
            .get('/providers/resend-verification-email')
            .then(() => {
                console.log('Verification email sent');
                toast({
                    title: `Verification email re-sent to ${user?.email}`,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            });
    };

    return (
        <Flex
            width={'100vw'}
            height={'100vh'}
            alignContent={'center'}
            justifyContent={'center'}
        >
            <Center>
                <VStack gap={8}>
                    <Heading size="lg" textAlign={'center'}>
                        Please check your inbox at {user?.email} and complete
                        email verification.
                    </Heading>
                    <Link
                        onClick={() => triggerVerificationEmail()}
                        textDecoration={'underline'}
                    >
                        Resend verification email
                    </Link>
                    <Link onClick={() => window.location.assign('/')}>
                        Back to login
                    </Link>
                    <Link onClick={() => logout()}>Logout</Link>
                </VStack>
            </Center>
        </Flex>
    );
}
