import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ONBOARDING_ROUTES } from '../Routes';

const ChangePathwayAction = () => {
    const navigate = useNavigate();

    return (
        <Button
            onClick={() =>
                navigate(ONBOARDING_ROUTES.pathway, {
                    state: {
                        reset: true,
                    },
                })
            }
        >
            Change Pathway
        </Button>
    );
};

export default ChangePathwayAction;
