import {
    Button,
    FormControl,
    HStack,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FieldValues, UseFormSetValue, useForm } from 'react-hook-form';
import { FiSearch } from 'react-icons/fi';
import { authorize } from '../api/API';
import { useAuth0 } from '@auth0/auth0-react';

interface NPISearchProps {
    // Define the props for your component here
    setValue: UseFormSetValue<FieldValues>;
    initialValue: string;
}

const NPISearch: React.FC<NPISearchProps> = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { register, getValues } = useForm();
    const { getAccessTokenSilently } = useAuth0();
    const [npiData, setNpiData] = React.useState<any>([]);
    const [selectedNpi, setSelectedNpi] = React.useState<any>(null);

    const loadNPIs = async (data: any) => {
        const token = await getAccessTokenSilently({});
        authorize(token)
            .get(
                `/provider-npi?firstName=${data.firstName}&lastName=${data.lastName}`,
            )
            .then((res) => {
                console.log('res', res.data);
                setNpiData(res.data);
            });
    };

    const search = () => {
        console.log('npi form', getValues());
        const data = getValues();
        loadNPIs(data);
    };

    const selectNpi = (npi: any) => () => {
        console.log('selected npi', npi);
        setSelectedNpi(npi);
        props.setValue('npi', npi.number);
        onClose();
    };

    return (
        <VStack>
            <HStack w="100%">
                <IconButton
                    onClick={onOpen}
                    data-cy="npi-open-btn"
                    icon={<FiSearch />}
                    aria-label="Open Search"
                />
                <Text>{props.initialValue ?? selectedNpi?.number}</Text>
            </HStack>

            <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>NPI Search</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mb={4}>
                        <VStack>
                            <HStack>
                                <form key={'npi-search-form'}>
                                    <FormControl>
                                        <HStack>
                                            <Input
                                                id="npiFirstName"
                                                placeholder="First name"
                                                {...register('firstName')}
                                            />
                                            <Input
                                                id="npiLastName"
                                                placeholder="Last name"
                                                {...register('lastName')}
                                            />
                                            <IconButton
                                                value="Search"
                                                onClick={search}
                                                data-cy="npi-search-btn"
                                                id="npi-search-btn"
                                                icon={<FiSearch />}
                                                aria-label="Search NPIs"
                                            />
                                        </HStack>
                                    </FormControl>
                                </form>
                            </HStack>

                            {npiData.length > 0 && (
                                <Table>
                                    <Thead>
                                        <Th>Name</Th>
                                        <Th>Taxonomies</Th>
                                        <Th>NPI</Th>
                                        <Th></Th>
                                    </Thead>
                                    <Tbody>
                                        {npiData.map(
                                            (npi: any, index: number) => (
                                                <Tr>
                                                    <Td>
                                                        {npi.basic.first_name}{' '}
                                                        {npi.basic.middle_name}{' '}
                                                        {npi.basic.last_name}
                                                    </Td>
                                                    <Td>
                                                        {npi.taxonomies.map(
                                                            (t: any) => (
                                                                <Text>
                                                                    {t.desc}
                                                                </Text>
                                                            ),
                                                        )}
                                                    </Td>
                                                    <Td>{npi.number}</Td>
                                                    <Td>
                                                        <Button
                                                            size="sm"
                                                            onClick={selectNpi(
                                                                npi,
                                                            )}
                                                            id={`select-npi-${index}`}
                                                        >
                                                            Select
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            ),
                                        )}
                                    </Tbody>
                                </Table>
                            )}
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </VStack>
    );
};

export default NPISearch;
