import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { authorize } from '../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../components/Loading';
import { useProvider } from '../components/PrivateRoute';
import { ONBOARDING_ROUTES } from './Routes';

interface Props {
    // Define your component's props here
}

export const OnboardingWrapper: React.FC<Props> = () => {
    const [formData, setFormData] = useState<any>(null);
    const { getAccessTokenSilently } = useAuth0();
    const { provider } = useProvider();
    const { pathname } = useLocation();

    const fetchData = async () => {
        const token = await getAccessTokenSilently();
        authorize(token)
            .get('/providers/onboard/form')
            .then((response) => {
                if (response.data) {
                    console.log('got form data', response.data);
                    setFormData(response.data.formData);
                } else {
                    setFormData({});
                }
            });
    };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    useEffect(() => {
        console.log('pathname changed', pathname);
        setFormData(null);
        fetchData();
    }, [pathname]);

    return formData ? <Outlet context={{ formData, provider }} /> : <Loading />;
};

export function useFormData() {
    return useOutletContext<any>();
}
