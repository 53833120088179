import {
    Card,
    CardBody,
    Container,
    Flex,
    HStack,
    Heading,
    SkeletonText,
    Stack,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import MainLayout from '../layouts/MainLayout';
import {
    FiCheckCircle,
    FiCircle,
    FiDollarSign,
    FiHelpCircle,
    FiUsers,
} from 'react-icons/fi';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import { authorize } from '../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { onboardingComplete } from '../util/Utils';
import PanelHeading from '../components/PanelHeading';

export function HomePage(): JSX.Element {
    const { getAccessTokenSilently } = useAuth0();
    const [providerProfile, setProviderProfile] = useState<any | undefined>();
    const [clients, setClients] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    const intakeFormComplete = () => onboardingComplete(providerProfile);

    const addClientsComplete = () => {
        if (clients && clients.length === 0) {
            return false;
        }
        return true;
    };

    const profileComplete = () => {
        if (!providerProfile) {
            return false;
        }
        return !!providerProfile?.bio;
    };

    const getProfileFn = (token: string) =>
        authorize(token)
            .get(`/providers/me`)
            .then((res): void => {
                setProviderProfile(res.data);
            });

    const getClientsFn = (token: string) =>
        authorize(token)
            .get(`/clients`)
            .then((res): void => {
                setClients(res.data);
            });

    useEffect(() => {
        // Fetch user data

        (async () => {
            setLoading(true);
            const token = await getAccessTokenSilently();

            Promise.all([getProfileFn(token), getClientsFn(token)]).finally(
                () => {
                    setLoading(false);
                },
            );
        })();
    }, []);

    const steps = [
        {
            title: 'Complete your intake form',
            isComplete: intakeFormComplete,
            link: '/profile/onboard',
        },
        {
            title: 'Complete your profile',
            isComplete: profileComplete,
            link: '/settings/profile',
        },
        {
            title: 'Connect your bank account information',
            isComplete: () => !!providerProfile?.user.stripePayoutsEnabled,
            link: '/settings/payment',
        },
        {
            title: 'Add clients so you can see them through Loula',
            isComplete: addClientsComplete,
            link: '/clients',
        },
    ];
    return (
        <MainLayout>
            <PanelHeading>Welcome to Loula</PanelHeading>
            <VStack
                bg={useColorModeValue('white', 'gray.900')}
                border={'1px'}
                p={10}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'start'}
                mt={4}
            >
                <Text>Start setting up your account</Text>
                <VStack align={'left'}>
                    {loading ? (
                        <SkeletonText
                            mt="4"
                            noOfLines={3}
                            spacing="4"
                            width={'100vh'}
                            skeletonHeight="2"
                        />
                    ) : (
                        steps.map((step, index) =>
                            step.isComplete() ? (
                                <HStack key={'step-' + index}>
                                    <FiCheckCircle />
                                    <Text>{step.title}</Text>
                                </HStack>
                            ) : (
                                <ChakraLink
                                    as={ReactRouterLink}
                                    to={step.link}
                                    key={'step-' + index}
                                >
                                    <HStack>
                                        <FiCircle />
                                        <Text>{step.title}</Text>
                                    </HStack>
                                </ChakraLink>
                            ),
                        )
                    )}
                </VStack>
            </VStack>
            <br />
            <PanelHeading>Loula How To</PanelHeading>
            <VStack
                mt={4}
                bg={useColorModeValue('white', 'gray.900')}
                border={'1px'}
                p={10}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'start'}
            >
                <Flex gap={4} align={'center'} justifyContent={'center'} mt={4}>
                    <Card
                        maxW="sm"
                        as="button"
                        onClick={() =>
                            window.open('https://support.joinloula.com')
                        }
                    >
                        <CardBody>
                            <Container centerContent mt={4}>
                                <FiUsers size={'75'} />
                            </Container>
                            <Stack mt="6" spacing="3">
                                <Heading size="md" textAlign={'center'}>
                                    Setting up clients
                                </Heading>
                                <Text>
                                    Learn how to add clients to your Loula
                                    account
                                </Text>
                            </Stack>
                        </CardBody>
                    </Card>

                    <Card
                        maxW="sm"
                        as="button"
                        onClick={() =>
                            window.open('https://support.joinloula.com')
                        }
                    >
                        <CardBody>
                            <Container centerContent mt={4}>
                                <FiDollarSign size={'75'} />
                            </Container>
                            <Stack mt="6" spacing="3">
                                <Heading size="md" textAlign={'center'}>
                                    Payments
                                </Heading>
                                <Text>
                                    Learn about how you get paid with Loula
                                </Text>
                            </Stack>
                        </CardBody>
                    </Card>

                    <Card
                        maxW="sm"
                        as="button"
                        onClick={() =>
                            window.open('https://support.joinloula.com')
                        }
                    >
                        <CardBody>
                            <Container centerContent mt={4}>
                                <FiHelpCircle size={'75'} />
                            </Container>
                            <Stack mt="6" spacing="3">
                                <Heading size="md" textAlign={'center'}>
                                    Get Help
                                </Heading>
                                <Text>
                                    Learn how to add clients to your Loula
                                    account
                                </Text>
                            </Stack>
                        </CardBody>
                    </Card>
                </Flex>
            </VStack>
        </MainLayout>
    );
}
