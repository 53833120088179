'use client';

import { useAuth0 } from '@auth0/auth0-react';
import {
    Avatar,
    Badge,
    Box,
    BoxProps,
    CloseButton,
    Divider,
    Drawer,
    DrawerContent,
    Flex,
    FlexProps,
    HStack,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    VStack,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import {
    FiChevronDown,
    FiHome,
    FiMenu,
    FiSettings,
    FiUsers,
    FiArrowLeft,
    FiDollarSign,
    FiCalendar,
    FiHelpCircle,
} from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProvider } from '../components/PrivateRoute';

interface LinkItemProps {
    name: string;
    icon: IconType;
    href?: string;
}

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: React.ReactNode;
    href: string;
    target?: string;
    name: string;
}

interface MobileProps extends FlexProps {
    onOpen: () => void;
    backButtonRoute: string;
    headerText?: string;
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const LinkItems: Array<LinkItemProps> = [
    { name: 'Home', icon: FiHome, href: '/' },
    { name: 'Clients', icon: FiUsers, href: '/clients' },
    { name: 'Visits', icon: FiCalendar, href: '/visits' },
    { name: 'Payments', icon: FiDollarSign, href: '/payments' },
    { name: 'Settings', icon: FiSettings, href: '/settings' },
];

if (import.meta.env.DEV)
    LinkItems.splice(2, 0, {
        name: 'Claims',
        icon: FiDollarSign,
        href: '/claims',
    });

const LinkItems2: Array<LinkItemProps> = [
    {
        name: 'Get Help',
        icon: FiHelpCircle,
        href: 'https://support.joinloula.com',
    },
];

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}
        >
            <Flex
                h="20"
                alignItems="center"
                mx="8"
                justifyContent="space-between"
            >
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                    Loula
                </Text>
                {import.meta.env.DEV && (
                    <Badge colorScheme="red">Staging</Badge>
                )}
                <CloseButton
                    display={{ base: 'flex', md: 'none' }}
                    onClick={onClose}
                />
            </Flex>
            {LinkItems.map((link) => (
                <NavItem
                    name={link.name}
                    key={link.name}
                    icon={link.icon}
                    href={link.href ?? ''}
                >
                    {link.name}
                </NavItem>
            ))}
            <Divider mt={4} mb={4} />
            {LinkItems2.map((link) => (
                <NavItem
                    key={link.name}
                    icon={link.icon}
                    href={link.href ?? ''}
                    target="_blank"
                    name={link.name}
                >
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};

const NavItem = ({ icon, children, href, name, ...rest }: NavItemProps) => {
    const { pathname } = useLocation();

    let isActive;
    try {
        isActive =
            !href.includes('http') &&
            pathname.split('/')[1] === href.split('/')[1];
    } catch (e) {
        isActive = false;
    }

    if (isActive) {
        rest.bg = 'teal.400';
        rest.color = 'white';
    }
    // console.log(pathname.split('/')[1], 'pathname');
    // console.log('href', href);
    return (
        <Box
            as="a"
            href={href}
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
            target={rest.target ?? '_self'}
            data-cy={`nav-${name}`}
        >
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'teal.400',
                    color: 'white',
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Box>
    );
};

const MobileNav = ({
    onOpen,
    backButtonRoute,
    headerText,
    ...rest
}: MobileProps) => {
    const { user, logout } = useAuth0();
    const navigate = useNavigate();
    const { provider } = useProvider();

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            bg={useColorModeValue('white', 'gray.900')}
            px={{ base: 4, md: 4 }}
            height="20"
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            alignItems="center"
        >
            <IconButton
                display={{
                    base: 'none',
                    md: !!backButtonRoute ? 'flex' : 'none',
                }}
                onClick={() => navigate(backButtonRoute)}
                variant="outline"
                aria-label="back button"
                icon={<FiArrowLeft />}
            />
            <Text
                display={{
                    base: 'none',
                    md: !!backButtonRoute ? 'flex' : 'none',
                }}
                fontSize="xl"
                ml={4}
            >
                {headerText}
            </Text>
            <Spacer />
            <Flex
                ml={{ base: 0, md: 60 }}
                height="20"
                alignItems="center"
                bg={useColorModeValue('white', 'gray.900')}
                borderBottomWidth="1px"
                borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
                justifyContent={{ base: 'space-between', md: 'flex-end' }}
                {...rest}
            >
                <IconButton
                    display={{ base: 'flex', md: 'none' }}
                    onClick={onOpen}
                    variant="outline"
                    aria-label="open menu"
                    icon={<FiMenu />}
                />

                <Text
                    display={{ base: 'flex', md: 'none' }}
                    fontSize="2xl"
                    fontFamily="monospace"
                    fontWeight="bold"
                >
                    Loula
                </Text>

                <HStack spacing={{ base: '0', md: '6' }}>
                    {/* <IconButton
                        size="lg"
                        variant="ghost"
                        aria-label="open menu"
                        icon={<FiBell />}
                    /> */}
                    <Flex alignItems={'center'}>
                        <Menu>
                            <MenuButton
                                py={2}
                                transition="all 0.3s"
                                _focus={{ boxShadow: 'none' }}
                            >
                                <HStack>
                                    <Avatar
                                        size={'sm'}
                                        name={user?.name}
                                        src={`https://loula-provider-files.s3.amazonaws.com/${provider.user.avatar}`}
                                        bg="purple.500"
                                    />
                                    <VStack
                                        display={{ base: 'none', md: 'flex' }}
                                        alignItems="flex-start"
                                        spacing="1px"
                                        ml="2"
                                    >
                                        <Text fontSize="sm">{user?.name}</Text>
                                    </VStack>
                                    <Box display={{ base: 'none', md: 'flex' }}>
                                        <FiChevronDown />
                                    </Box>
                                </HStack>
                            </MenuButton>
                            <MenuList
                                bg={useColorModeValue('white', 'gray.900')}
                                borderColor={useColorModeValue(
                                    'gray.200',
                                    'gray.700',
                                )}
                            >
                                <MenuItem
                                    onClick={() =>
                                        navigate('/settings/profile')
                                    }
                                >
                                    Profile
                                </MenuItem>
                                <MenuItem onClick={() => navigate('/settings')}>
                                    Settings
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                    onClick={() =>
                                        logout({
                                            logoutParams: {
                                                returnTo:
                                                    window.location.origin,
                                            },
                                        })
                                    }
                                >
                                    Sign out
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </HStack>
            </Flex>
        </Flex>
    );
};

const MainLayout = ({
    children,
    backButtonRoute = '',
    headerText,
}: {
    children: React.ReactNode;
    backButtonRoute?: string;
    headerText?: string;
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { provider } = useProvider();
    console.log(provider);

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}

            <MobileNav
                onOpen={onOpen}
                backButtonRoute={backButtonRoute}
                headerText={headerText}
            />
            <Box ml={{ base: 0, md: 60 }} p="8">
                {children}
            </Box>
        </Box>
    );
};

export default MainLayout;
