import { useAuth0 } from '@auth0/auth0-react';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authorize } from '../api/API';
import MainLayout from '../layouts/MainLayout';
import {
    Box,
    Button,
    Flex,
    FormControl,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Spacer,
    Text,
    VStack,
    useColorModeValue,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import PanelHeading from '../components/PanelHeading';
import { FiPlus } from 'react-icons/fi';

const ModalForm = ({
    handleClientSelected,
}: {
    handleClientSelected: (client: any) => void;
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { getAccessTokenSilently } = useAuth0();

    const [options, setOptions] = useState<any[] | undefined>([]);
    const [selectedClient, setSelectedClient] = useState<any | undefined>(
        undefined,
    );

    const onSubmit = (e: SubmitEvent) => {
        e.preventDefault();

        if (selectedClient === undefined) {
            return;
        }
        handleClientSelected(selectedClient);
        onClose();
    };

    // get list of clients
    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently();
            authorize(token)
                .get(`/clients`)
                .then((res): void => {
                    const clients = res.data;
                    // @todo: fix any type
                    const options = clients.map((c: any) => {
                        return {
                            label: c.patient.user.preferredName
                                ? c.patient.user.preferredName
                                : c.patient.user.firstName +
                                  ' ' +
                                  c.patient.user.lastName,
                            value: c.patient.userId,
                        };
                    });
                    setOptions(options);
                });
        })();
    }, []);

    return (
        <Box>
            <Button onClick={onOpen} leftIcon={<FiPlus />}>
                Create Claim
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Select Client</ModalHeader>
                    <ModalCloseButton />
                    <form
                        onSubmit={(e: FormEvent<HTMLFormElement>) =>
                            onSubmit(e as any)
                        }
                    >
                        <ModalBody>
                            <FormControl>
                                <Select
                                    options={options}
                                    name="client"
                                    onChange={(client) =>
                                        setSelectedClient(client)
                                    }
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} type="submit">
                                Start
                            </Button>
                            <Button variant="ghost" onClick={onClose}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </Box>
    );
};

/**
 * This is an example of a generic "Resource Display" page.
 * It uses the Medplum `<ResourceTable>` component to display a resource.
 * @returns A React component that displays a resource.
 */
export function Claims(): JSX.Element | null {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [claims, setclaims] = useState<any[] | undefined>(undefined);

    const token = getAccessTokenSilently();
    const [searchParams] = useSearchParams();
    const toast = useToast();

    const clientCreatedToastID = 'claim-created-toast';

    useEffect(() => {
        // please fix this!
        if (token == null) {
            return;
        }

        (async () => {
            const token = await getAccessTokenSilently();
            authorize(token)
                .get(`/claims`)
                .then((res): void => {
                    const claims = res.data;
                    console.log('claims ', claims);
                    setclaims(claims);
                });
        })();
    }, []);

    useEffect(() => {
        if (
            searchParams.get('created') &&
            !toast.isActive(clientCreatedToastID)
        ) {
            toast({
                id: clientCreatedToastID,
                title: 'Claim created.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }
    }, []);

    const handleClientSelected = (client: any) => {
        console.log('client selected', client);
        navigate(`/claims/${client.value}/create`);
    };

    const getTotalCharges = (serviceLines: any[]) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        const total = serviceLines
            .map((line) => line.charges)
            .reduce((a, b) => a + b);
        return formatter.format(total);
    };

    return (
        <MainLayout>
            <VStack
                bg={useColorModeValue('white', 'gray.900')}
                border={'1px'}
                p={8}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'start'}
                mb={6}
            >
                <HStack width="100%">
                    <PanelHeading>Claims</PanelHeading>
                    <Spacer />
                    <ModalForm handleClientSelected={handleClientSelected} />
                </HStack>

                <Box width={'100%'}>
                    <Flex flexDirection={'column'} gap={4}>
                        <HStack
                            direction="row"
                            spacing={4}
                            justify="right"
                        ></HStack>
                        <Skeleton isLoaded={claims !== undefined}>
                            <TableContainer width={'100%'}>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>Ref #</Th>
                                            <Th>Name</Th>
                                            <Th>Email</Th>
                                            <Th>Total Charges</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {claims?.map((claim: any) => (
                                            <Tr key={claim.code}>
                                                <Td>
                                                    <Text as="kbd">
                                                        {claim.code}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    {claim.patient?.user
                                                        .preferredName
                                                        ? claim.patient.user
                                                              .preferredName
                                                        : claim.patient?.user
                                                              .firstName}{' '}
                                                    {
                                                        claim.patient?.user
                                                            ?.lastName
                                                    }
                                                </Td>
                                                <Td>
                                                    {claim.patient?.user?.email}
                                                </Td>
                                                <Td>
                                                    {getTotalCharges(
                                                        claim.serviceLines,
                                                    )}
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Skeleton>
                    </Flex>
                </Box>
            </VStack>
        </MainLayout>
    );
}
