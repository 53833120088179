import React, { useCallback } from 'react';
import { authorize } from '../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import Dropzone from './Dropzone';
import { UseFormReturn } from 'react-hook-form';
import { Flex, IconButton, Link, Stack } from '@chakra-ui/react';
import { FiXCircle } from 'react-icons/fi';

interface FileUploadProps {
    // Define your component props here
    loading: boolean;
    setLoading: (loading: boolean) => void;
    fieldName: string;
    maxFiles?: number;
    fieldValue: any;
    ['data-cy']?: string;
    formInstance: UseFormReturn<any>;
}

const FileUpload: React.FC<FileUploadProps> = ({ maxFiles = 3, ...props }) => {
    const { getAccessTokenSilently } = useAuth0();
    const token = getAccessTokenSilently();
    const uploadFileFn = async (token: string, file: any) => {
        const formData = new FormData();
        formData.append('file', file);

        const result = await authorize(token).post(
            '/providers/upload-temp-file',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );

        return result.data;
    };

    const fieldWatch = props.formInstance.watch(props.fieldName);

    const onDrop = useCallback(
        async (acceptedFiles: any) => {
            // upload temporary file to API
            props.setLoading(true);
            if (Array.isArray(acceptedFiles)) {
                for (let i = 0; i < acceptedFiles.length; i++) {
                    const file = acceptedFiles[i];
                    await uploadFileFn(await token, file)
                        .then((res): void => {
                            const newFile = {
                                originalFileName: res.originalFileName,
                                temporaryFileName: res.temporaryFileName,
                            };
                            console.log('adding new file', newFile);

                            if (maxFiles && maxFiles > 0) {
                                if (Array.isArray(fieldWatch)) {
                                    props.formInstance.setValue(
                                        props.fieldName,
                                        [...fieldWatch, newFile],
                                    );
                                } else {
                                    props.formInstance.setValue(
                                        props.fieldName,
                                        [newFile],
                                    );
                                }
                            } else {
                                props.formInstance.setValue(props.fieldName, [
                                    newFile,
                                ]);
                            }
                        })
                        .finally(() => {
                            props.setLoading(false);
                            props.formInstance.trigger(props.fieldName);
                        });
                }
            }
        },
        [props.formInstance, props.setLoading, fieldWatch],
    );

    const deleteFile = (fileIndex: number) => {
        const splicedArray = fieldWatch.toSpliced(fileIndex, 1);
        props.formInstance.setValue(props.fieldName, splicedArray);
    };

    return (
        <Stack>
            <Dropzone
                data-cy={props['data-cy']}
                maxFiles={maxFiles}
                onDropAccepted={onDrop}
            />
            {Array.isArray(fieldWatch) &&
                fieldWatch.map((file: any, index: number) => (
                    <Flex gap={4} alignItems={'center'}>
                        <IconButton
                            onClick={() => deleteFile(index)}
                            aria-label="Remove File"
                            icon={<FiXCircle />}
                        />

                        <Link>{file.originalFileName}</Link>
                    </Flex>
                ))}
        </Stack>
    );
};

export default FileUpload;
