import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
} from '@chakra-ui/react';
import humanizeString from 'humanize-string';
import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import {
    Control,
    Controller,
    FieldErrors,
    FieldValues,
    UseFormRegister,
    UseFormSetValue,
} from 'react-hook-form';

interface AddressFormProps {
    control: Control;
    errors: FieldErrors<FieldValues>;
    register: UseFormRegister<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    namePrefix?: string; // Used to prefix the name of the fields
}
const AddressForm: React.FC<AddressFormProps> = ({
    control,
    errors,
    register,
    setValue,
    namePrefix = '',
}) => {
    // Implement your component logic here

    const formNamePrefix = namePrefix.length > 0 ? namePrefix + '.' : '';

    const { ref } = usePlacesWidget<HTMLInputElement>({
        apiKey: 'AIzaSyATDhJYAZxBIMl8IJBEQXtCvXwwDeVvdFU',
        onPlaceSelected: (place) => {
            const {
                streetNumber,
                streetName,
                subpremise,
                city,
                state,
                postalCode,
            } = parsePlaceResponse(place);
            if (ref.current)
                ref.current.value = `${streetNumber} ${streetName}`;
            setValue(
                formNamePrefix + 'address1',
                `${streetNumber} ${streetName}`,
            );
            setValue(formNamePrefix + 'address2', subpremise);
            setValue(formNamePrefix + 'city', city);
            setValue(formNamePrefix + 'state', state);
            setValue(formNamePrefix + 'zip', postalCode);
        },
        options: {
            componentRestrictions: { country: 'us' },
            types: ['address'],
            inputAutocompleteValue: '',
        },
    });

    const parsePlaceResponse = (place: any) => {
        console.log('place', place);
        const { address_components } = place;
        let streetNumber = '';
        let streetName = '';
        let subpremise;
        let city = '';
        let state = '';
        let postalCode = '';

        address_components.forEach((component: any) => {
            const { types, long_name } = component;
            if (types.includes('street_number')) {
                streetNumber = long_name;
            } else if (types.includes('route')) {
                streetName = long_name;
            } else if (
                types.includes('locality') ||
                types.includes('sublocality')
            ) {
                city = long_name;
            } else if (types.includes('administrative_area_level_1')) {
                state = long_name;
            } else if (types.includes('postal_code')) {
                postalCode = long_name;
            } else if (types.includes('subpremise')) {
                subpremise = long_name;
            }
        });

        return {
            streetNumber,
            streetName,
            subpremise,
            city,
            state,
            postalCode,
        };
    };

    const getIsInvalid = (field: string) => {
        const error =
            namePrefix.length > 0
                ? errors[namePrefix] && (errors[namePrefix] as any)[field]
                : errors[field] && errors[field]?.message?.toString();

        return !!error;
    };

    const getErrorMessage = (field: string) => {
        return namePrefix.length > 0
            ? errors[namePrefix] &&
                  (errors[namePrefix] as any)[field]?.message?.toString()
            : errors[field] && errors[field]?.message?.toString();
    };

    return (
        <>
            <FormControl isInvalid={getIsInvalid('address1')} mt={4} isRequired>
                <FormLabel htmlFor="address1">Address</FormLabel>

                <Controller
                    control={control}
                    name={formNamePrefix + 'address1'}
                    render={({ field }) => (
                        <Input
                            id="address1"
                            placeholder="Address"
                            {...field}
                            ref={ref}
                        />
                    )}
                />
                <FormErrorMessage>
                    {getErrorMessage('address1')}
                </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={getIsInvalid('address2')} mt={4}>
                <FormLabel htmlFor="address2">Address 2</FormLabel>
                <Input
                    id={formNamePrefix + 'address2'}
                    placeholder="Address"
                    {...register(formNamePrefix + 'address2')}
                />
                <FormErrorMessage>
                    {getErrorMessage('address2')}
                </FormErrorMessage>
            </FormControl>

            {['city', 'state', 'zip'].map((field) => (
                <FormControl isInvalid={getIsInvalid(field)} mt={4} isRequired>
                    <FormLabel htmlFor={field} textTransform={'capitalize'}>
                        {field}
                    </FormLabel>
                    <Input
                        id={field}
                        placeholder={field}
                        textTransform={'capitalize'}
                        {...register(formNamePrefix + field, {
                            required: `${humanizeString(field)} is required`,
                        })}
                    />
                    <FormErrorMessage>
                        {getErrorMessage(field)}
                    </FormErrorMessage>
                </FormControl>
            ))}
        </>
    );
};

export default AddressForm;
