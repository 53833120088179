// Chakra imports
import {
    Button,
    Flex,
    Input,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
// Assets
import { useDropzone } from 'react-dropzone';

function Dropzone({
    onDropAccepted,
    maxFiles = 0,
    ...props
}: {
    onDropAccepted: any;
    maxFiles?: number;
    [x: string]: any;
}) {
    const [dropError, setDropError] = React.useState<string | null>(null);

    const _onDropAccepted = (files: any) => {
        setDropError(null);
        if (onDropAccepted) onDropAccepted(files);
    };

    const _onDropRejected = (files: any) => {
        console.log('rejected files', files);
        setDropError('Upload a valid image or PDF file');
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.png', '.jpeg', '.jpg'],
            'application/pdf': ['.pdf'],
        },
        onDropAccepted: _onDropAccepted,
        onDropRejected: _onDropRejected,
        maxFiles,
        multiple: maxFiles > 1,
        maxSize: 10485760, // 10MB
    });
    const bg = useColorModeValue('gray.100', 'navy.700');
    const borderColor = useColorModeValue(
        'secondaryGray.100',
        'whiteAlpha.100',
    );

    return (
        <Flex
            data-cy={props['data-cy']}
            align="center"
            justify="center"
            bg={bg}
            border="1px dashed"
            borderColor={borderColor}
            borderRadius="16px"
            w="100%"
            h="max-content"
            minH="100%"
            cursor="pointer"
            {...getRootProps({ className: 'dropzone' })}
            {...props}
        >
            <Input {...getInputProps()} size={'sm'} data-cy="file-upload" />
            <VStack>
                <Button variant="no-effects">Upload or drop files here</Button>
                <Text mb={4}>
                    Accepted file types: .jpg, .png, .pdf Max: 10MB
                </Text>
                {dropError && (
                    <Text color="red" mb={4}>
                        {dropError}
                    </Text>
                )}
            </VStack>
        </Flex>
    );
}

export default Dropzone;
