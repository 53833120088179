import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import FileUpload from '../../components/FileUpload';

interface Props {
    formInstance: UseFormReturn<any>;
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

const CPRCertificationUpload: React.FC<Props> = ({
    formInstance,
    loading,
    setLoading,
}) => {
    return (
        <FormControl
            isRequired
            isInvalid={!!formInstance.formState.errors.cprCertificate}
        >
            <FormLabel mt={4}>
                Upload a copy of your CPR training certificate
            </FormLabel>
            <Controller
                control={formInstance.control}
                name="cprCertificate"
                rules={{
                    required: 'This is required',
                }}
                render={({ field }) => (
                    <FileUpload
                        fieldName={field.name}
                        fieldValue={field.value}
                        formInstance={formInstance}
                        loading={loading}
                        setLoading={setLoading}
                        maxFiles={1}
                    />
                )}
            />

            <FormErrorMessage>
                {formInstance.formState.errors.cprCertificate &&
                    formInstance.formState.errors.cprCertificate.message?.toString()}
            </FormErrorMessage>
        </FormControl>
    );
};

export default CPRCertificationUpload;
