import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    Heading,
    Input,
    Select,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { faker } from '@faker-js/faker';
import FullScreenLayout from '../layouts/FullScreenLayout';
import Steps from './Steps';
import AddressForm from '../components/AddressForm';
import NPISearch from '../components/NPISearch';
import { useSaveForm } from './SaveForm';
import { useFormData } from './OnboardingWrapper';
import FileUpload from '../components/FileUpload';
import PanelHeading from '../components/PanelHeading';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DOB from '../components/DOB';

dayjs.extend(customParseFormat);

export function Onboard(): JSX.Element {
    const navigate = useNavigate();
    const toast = useToast();
    const { saveForm } = useSaveForm();
    const { formData } = useFormData();

    const [loading, setLoading] = useState<boolean>(false);

    const formInstance = useForm({ defaultValues: formData });
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        setValue,
        control,
    } = formInstance;

    console.log('setting up form', formData);

    async function onSubmit(values: any) {
        console.log('Submitting form', values);

        try {
            await saveForm(values);
            navigate('/onboarding/identity');
        } catch (err) {
            console.error('Error saving form', err);
            toast({
                title: 'Something went wrong, please try again later.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    }

    async function demoData() {
        const randomEmail = faker.internet.email();
        const randomFirstName = faker.person.firstName('female');
        const randomLastName = faker.person.lastName();
        const randomPhone = faker.phone.number();

        const randomDOB = faker.date.birthdate();

        setValue('firstName', randomFirstName);
        setValue('lastName', randomLastName);
        setValue('preferredName', faker.person.firstName('female'));
        setValue('pronouns', 'she/her');
        setValue('gender', 'woman');
        setValue('email', randomEmail);
        setValue('phone', randomPhone);
        setValue('dob', dayjs(randomDOB).format('MM/DD/YYYY'));

        setValue('address1', faker.location.streetAddress());
        setValue('address2', faker.location.secondaryAddress());
        setValue('city', faker.location.city());
        setValue('state', faker.location.state());
        setValue('zip', faker.location.zipCode());

        setValue('npi', faker.string.numeric(10));
        setValue('tin', faker.number.int());
    }

    return (
        <FullScreenLayout>
            <VStack gap={8} mt={4}>
                <Steps currentStep={1} />
                <VStack>
                    <VStack
                        bg={useColorModeValue('white', 'gray.900')}
                        border={'1px'}
                        p={8}
                        borderColor={useColorModeValue('gray.200', 'gray.700')}
                        align={'start'}
                        mb={6}
                        w="75%"
                    >
                        {/* <Text mb="lg" fontSize="2xl">
                            Get onboarded
                        </Text> */}

                        <Button onClick={demoData} colorScheme="orange">
                            Demo Data
                        </Button>

                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <PanelHeading>About You</PanelHeading>

                            <HStack>
                                <FormControl
                                    isInvalid={!!errors.firstName}
                                    mt={4}
                                    isRequired
                                >
                                    <FormLabel htmlFor="firstName">
                                        Legal First Name
                                    </FormLabel>
                                    <Input
                                        id="firstName"
                                        placeholder="Name"
                                        {...register('firstName', {
                                            required: 'Name is required',
                                            minLength: {
                                                value: 1,
                                                message:
                                                    'Minimum length should be 1',
                                            },
                                        })}
                                    />
                                    <FormErrorMessage>
                                        {errors.firstName &&
                                            errors.firstName.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    isInvalid={!!errors.lastName}
                                    mt={4}
                                    isRequired
                                >
                                    <FormLabel htmlFor="lastName">
                                        Legal Last Name
                                    </FormLabel>
                                    <Input
                                        id="lastName"
                                        placeholder="Name"
                                        {...register('lastName', {
                                            required: 'Name is required',
                                            minLength: {
                                                value: 1,
                                                message:
                                                    'Minimum length should be 1',
                                            },
                                        })}
                                    />
                                    <FormErrorMessage>
                                        {errors.lastName &&
                                            errors.lastName.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>
                            </HStack>

                            <FormControl
                                isInvalid={!!errors.preferredName}
                                mt={4}
                            >
                                <FormLabel htmlFor="preferredName">
                                    Preferred name
                                </FormLabel>
                                <Input
                                    id="preferredName"
                                    placeholder="Name"
                                    {...register('preferredName', {
                                        minLength: {
                                            value: 1,
                                            message:
                                                'Minimum length should be 1',
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.preferredName &&
                                        errors.preferredName.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!errors.pronouns} mt={4}>
                                <FormLabel htmlFor="pronouns">
                                    Pronouns
                                </FormLabel>
                                <Input
                                    id="pronouns"
                                    placeholder="she/her, he/him, they/them"
                                    {...register('pronouns', {
                                        minLength: {
                                            value: 2,
                                            message:
                                                'Minimum length should be 2',
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.lastName &&
                                        errors.lastName.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                isInvalid={!!errors.gender}
                                mt={4}
                                isRequired
                            >
                                <FormLabel htmlFor="gender">Gender</FormLabel>
                                <Select
                                    {...register('gender', {
                                        required: 'Gender is required',
                                    })}
                                    placeholder="Select"
                                    data-cy="gender-dropdown"
                                >
                                    <option value="woman">Woman</option>
                                    <option value="man">Man</option>
                                    <option value="nonbinary">
                                        Non-binary
                                    </option>
                                    <option value="other">
                                        I use another term
                                    </option>
                                    <option value="prefer_not_to_say">
                                        Prefer not to say
                                    </option>
                                </Select>
                                <FormErrorMessage>
                                    {errors.gender &&
                                        errors.gender.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                isInvalid={!!errors.phone}
                                mt={4}
                                isRequired
                            >
                                <FormLabel htmlFor="phone">Phone</FormLabel>
                                <Input
                                    id="phone"
                                    placeholder="Phone"
                                    {...register('phone', {
                                        required: 'Phone is required',
                                        minLength: {
                                            value: 10,
                                            message:
                                                'Minimum length should be 10',
                                        },
                                        maxLength: {
                                            value: 10,
                                            message:
                                                'Maximum length should be 10',
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: 'Only numbers are allowed',
                                        },
                                    })}
                                />
                                <FormHelperText>
                                    Enter your phone number using just numbers,
                                    e.g. 4151234567
                                </FormHelperText>
                                <FormErrorMessage>
                                    {errors.phone &&
                                        errors.phone.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <DOB formInstance={formInstance} />

                            <AddressForm
                                control={control}
                                errors={errors}
                                register={register}
                                setValue={setValue}
                            />

                            {/* <FormControl isInvalid={!!errors.address1} mt={4}>
                                <FormLabel htmlFor="address1">
                                    Address
                                </FormLabel>
                                <Input
                                    id="address1"
                                    placeholder="Address"
                                    {...register('address1')}
                                />
                                <FormErrorMessage>
                                    {errors.address1 &&
                                        errors.address1.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!errors.address2} mt={4}>
                                <FormLabel htmlFor="address2">
                                    Address 2
                                </FormLabel>
                                <Input
                                    id="address2"
                                    placeholder="Address"
                                    {...register('address2')}
                                />
                                <FormErrorMessage>
                                    {errors.address2 &&
                                        errors.address2.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <HStack>
                                <FormControl isInvalid={!!errors.city} mt={4}>
                                    <FormLabel htmlFor="city">City</FormLabel>
                                    <Input
                                        id="city"
                                        {...register('address2')}
                                    />
                                    <FormErrorMessage>
                                        {errors.address2 &&
                                            errors.address2.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.address2}
                                    mt={4}
                                >
                                    <FormLabel htmlFor="state">State</FormLabel>
                                    <Input id="state" {...register('state')} />
                                    <FormErrorMessage>
                                        {errors.state &&
                                            errors.state.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors.zip} mt={4}>
                                    <FormLabel htmlFor="zip">Zip</FormLabel>
                                    <Input id="zip" {...register('zip')} />
                                    <FormErrorMessage>
                                        {errors.zip &&
                                            errors.zip.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>
                            </HStack> */}

                            <Divider mt={4} />

                            <Heading mt={4} size="sm" id="identifiers">
                                Identifiers
                            </Heading>

                            <FormControl
                                isInvalid={!!errors.npi}
                                mt={4}
                                isRequired
                            >
                                <FormLabel htmlFor="npi">NPI Number</FormLabel>
                                <NPISearch
                                    setValue={setValue}
                                    initialValue={formData?.npi}
                                />
                                <Input
                                    id="npi"
                                    type="hidden"
                                    {...register('npi', {
                                        required: 'This is required',
                                        minLength: {
                                            value: 10,
                                            message:
                                                'Minimum length should be 10',
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.npi &&
                                        errors.npi.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                isInvalid={!!errors.tin}
                                mt={4}
                                isRequired
                            >
                                <FormLabel htmlFor="tin">
                                    Social Security Number
                                </FormLabel>
                                <Input
                                    id="tin"
                                    placeholder="TIN"
                                    {...register('tin', {
                                        required: 'This is required',
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: 'Only numbers are allowed',
                                        },
                                        minLength: {
                                            value: 9,
                                            message:
                                                'Minimum length should be 9',
                                        },
                                        maxLength: {
                                            value: 9,
                                            message:
                                                'Maximum length should be 9',
                                        },
                                    })}
                                />
                                <FormHelperText>
                                    Enter your SSN without hyphens
                                </FormHelperText>
                                <FormErrorMessage>
                                    {errors.tin &&
                                        errors.tin.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                isInvalid={!!errors.mediCalEnrollmentFile}
                                mt={4}
                                isRequired
                            >
                                <FormLabel>
                                    Proof of Medi-Cal Enrollment
                                </FormLabel>
                                <Box>
                                    <Controller
                                        control={control}
                                        name="mediCalEnrollmentFile"
                                        rules={{
                                            required: 'This is required',
                                        }}
                                        render={({ field }) => (
                                            <FileUpload
                                                data-cy="medi-cal-enrollment-file"
                                                fieldName={field.name}
                                                loading={loading}
                                                setLoading={setLoading}
                                                maxFiles={1}
                                                fieldValue={field.value}
                                                formInstance={formInstance}
                                            />
                                        )}
                                    />
                                </Box>
                                <FormErrorMessage>
                                    {errors.mediCalEnrollmentFile &&
                                        errors.mediCalEnrollmentFile.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                isInvalid={!!errors.proofOfLiabilityInsurance}
                                mt={4}
                                isRequired
                            >
                                <FormLabel>
                                    Proof of Liability Insurance
                                </FormLabel>
                                <FormHelperText>
                                    You are required to hold $1mm professional
                                    liability insurance to work with Loula.
                                    Upload a copy of your proof of coverage
                                    including coverage limits.
                                </FormHelperText>
                                <Box mt={4}>
                                    <Controller
                                        control={control}
                                        name="proofOfLiabilityInsurance"
                                        rules={{
                                            required: 'This is required',
                                        }}
                                        render={({ field }) => (
                                            <FileUpload
                                                data-cy="proof-of-liability-insurance"
                                                fieldName={field.name}
                                                loading={loading}
                                                setLoading={setLoading}
                                                maxFiles={1}
                                                fieldValue={field.value}
                                                formInstance={formInstance}
                                            />
                                        )}
                                    />
                                </Box>
                                <FormErrorMessage>
                                    {errors.proofOfLiabilityInsurance &&
                                        errors.proofOfLiabilityInsurance.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>

                            <Button
                                mt={4}
                                colorScheme="teal"
                                isLoading={isSubmitting}
                                isDisabled={loading}
                                type="submit"
                                data-cy="submit-button"
                            >
                                Submit
                            </Button>
                        </form>
                    </VStack>
                </VStack>
            </VStack>
        </FullScreenLayout>
    );
}
