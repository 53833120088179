import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    ListItem,
    UnorderedList,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import FullScreenLayout from '../layouts/FullScreenLayout';
import Steps from './Steps';
import PanelHeading from '../components/PanelHeading';
import FileUpload from '../components/FileUpload';
import { useFormData } from './OnboardingWrapper';
import { useSaveForm } from './SaveForm';
import YesNoRadioGroup from '../components/YesNoRadioGroup';
import StepActions from './components/StepActions';
import CPRCertificationUpload from './components/CPRCertificationUpload';
import HIPAACertificateUpload from './components/HIPAACertificateUpload';
import ChangePathwayAction from './components/ChangePathwayAction';

export function ExperiencePathway(): JSX.Element {
    const navigate = useNavigate();
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const { formData } = useFormData();
    const { saveForm } = useSaveForm();

    const formInstance = useForm({
        defaultValues: {
            proofOfExperience: formData?.proofOfExperience,
            cprCompletion: formData?.cprCompletion,
            cprCertificate: formData?.cprCertificate,
            hipaaCompletion: formData?.hipaaCompletion,
            hipaaCertificate: formData?.hipaaCertificate,
        },
    });
    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
    } = formInstance;

    async function onSubmit(values: any, save: boolean = false) {
        console.log('Submitting form', values);
        try {
            await saveForm(values);
            if (!save) {
                navigate('/onboarding/resume');
            } else {
                toast({
                    title: 'Progress saved',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            console.error('Error saving form', err);
            toast({
                title: 'Something went wrong, please try again later.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    }

    const cprCompletionWatch = watch('cprCompletion');

    return (
        <FullScreenLayout>
            <VStack gap={8} mt={4}>
                <Steps currentStep={3} />

                <form
                    onSubmit={handleSubmit((values) => onSubmit(values, false))}
                    noValidate
                >
                    <Container
                        bg={useColorModeValue('white', 'gray.900')}
                        border={'1px'}
                        p={8}
                        borderColor={useColorModeValue('gray.200', 'gray.700')}
                        mb={6}
                        maxW={'3xl'}
                    >
                        <PanelHeading>Experience Pathway</PanelHeading>
                        <FormControl
                            id="proofOfExperience"
                            isRequired
                            isInvalid={!!errors.proofOfExperience}
                        >
                            <FormLabel>Proof of Experience</FormLabel>
                            <FormHelperText mb={4}>
                                Please upload three client testimonial letters
                                or professional letters of recommendation
                                written within the last seven years from any of
                                the following:
                            </FormHelperText>
                            <FormHelperText mb={4}>
                                <UnorderedList fontSize={'sm'}>
                                    <ListItem>
                                        A physician, nurse practitioner, nurse
                                        midwife, or licensed midwife
                                    </ListItem>
                                    <ListItem>
                                        A licensed behavioral health provider
                                    </ListItem>
                                    <ListItem>An enrolled doula</ListItem>
                                    <ListItem>
                                        A community-based organization
                                    </ListItem>
                                </UnorderedList>
                            </FormHelperText>
                            <FormHelperText mb={4}>
                                One letter must be from either a licensed
                                provider, a community-based organization, or an
                                enrolled doula.
                            </FormHelperText>
                            <Controller
                                control={control}
                                name="proofOfExperience"
                                rules={{
                                    required: 'This is required',
                                    validate: {
                                        minFiles: (value: any) => {
                                            if (
                                                Array.isArray(value) &&
                                                value.length < 3
                                            ) {
                                                return 'Upload at least 3 files';
                                            }
                                        },
                                    },
                                }}
                                render={({ field }) => (
                                    <FileUpload
                                        fieldName={field.name}
                                        loading={loading}
                                        setLoading={setLoading}
                                        maxFiles={1}
                                        fieldValue={field.value}
                                        formInstance={formInstance}
                                    />
                                )}
                            />

                            <FormErrorMessage>
                                {errors.proofOfExperience &&
                                    errors.proofOfExperience.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl
                            mt={4}
                            isRequired
                            isInvalid={!!errors.cprCompletion}
                        >
                            <FormLabel>
                                Have you completed a CPR certification course?
                            </FormLabel>
                            <Controller
                                control={control}
                                name="cprCompletion"
                                rules={{
                                    required: 'This is required',
                                }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <YesNoRadioGroup field={field} />
                                )}
                            />
                            <FormErrorMessage>
                                {errors.cprCompletion &&
                                    errors.cprCompletion.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>
                        {cprCompletionWatch === 'no' && (
                            <FormLabel mt={4} color={'red'}>
                                You'll need to have completed a CPR
                                certification course to join Loula.
                            </FormLabel>
                        )}
                        {cprCompletionWatch === 'yes' && (
                            <CPRCertificationUpload
                                formInstance={formInstance}
                                loading={loading}
                                setLoading={setLoading}
                            />
                        )}
                        <HIPAACertificateUpload
                            formInstance={formInstance}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    </Container>

                    <StepActions
                        formInstance={formInstance}
                        onSubmit={onSubmit}
                        isNextDisabled={cprCompletionWatch === 'no' || loading}
                        leftAction={<ChangePathwayAction />}
                    />
                </form>
            </VStack>
        </FullScreenLayout>
    );
}
